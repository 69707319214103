import type { ISupplierBeauty } from '@bridebook/models/source/models/RTDB/Suppliers/Beauty.types';
import type { ISupplierCatering } from '@bridebook/models/source/models/RTDB/Suppliers/Catering.types';
import type { ISupplierDecoration } from '@bridebook/models/source/models/RTDB/Suppliers/Decoration.types';
import type { ISupplierDress } from '@bridebook/models/source/models/RTDB/Suppliers/Dress.types';
import type { ISupplierEntertainment } from '@bridebook/models/source/models/RTDB/Suppliers/Entertainment.types';
import type { ISupplierJewellery } from '@bridebook/models/source/models/RTDB/Suppliers/Jewellery.types';
import type { ISupplierMarquee } from '@bridebook/models/source/models/RTDB/Suppliers/Marquee.types';
import type { ISupplierMenswear } from '@bridebook/models/source/models/RTDB/Suppliers/Menswear.types';
import type { ISupplierMusic } from '@bridebook/models/source/models/RTDB/Suppliers/Music.types';
import type { ISupplierPhoto } from '@bridebook/models/source/models/RTDB/Suppliers/Photo.types';
import type { ISupplierPlanner } from '@bridebook/models/source/models/RTDB/Suppliers/Planners.types';
import type { ISupplierStationery } from '@bridebook/models/source/models/RTDB/Suppliers/Stationery.types';
import type { ISupplierTransport } from '@bridebook/models/source/models/RTDB/Suppliers/Transport.types';
import type { ISupplierVenue } from '@bridebook/models/source/models/RTDB/Suppliers/Venue.types';
import type { ISupplierVideo } from '@bridebook/models/source/models/RTDB/Suppliers/Video.types';
import type { ISupplier, ISupplierDetailCake, ISupplierDetailFlorist } from '@bridebook/models/source/models/Suppliers.types';
import type { Slug } from '@bridebook/toolbox/src/types';

const extraFilterProps: Partial<Record<Slug, any>> = {
  beauty: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      airbrush: false,
      beautySalons: false,
      cosmeticSurgery: false,
      delivery: false,
      groupBookings: false,
      hairdressing: false,
      hairExtensions: false,
      laserHairRemoval: false,
      makeUpArtists: false,
      massage: false,
      mobileServices: false,
      nailArtists: false,
      nutritionist: false,
      onlineShop: false,
      onTheDayServices: false,
      personalTrainer: false,
      physicalShopStudio: false,
      preWeddingConsultation: false,
      preWeddingTrial: false,
      stayThroughCeremony: false,
      stayThroughDancing: false,
      stayThroughReception: false,
      tanning: false,
      tattooCoverUpMakeUp: false,
      teethWhitening: false,
      threading: false,
      waxing: false,
    } as ISupplierBeauty['productsServicesSection'],
  },
  cakes: {
    renderSections: [
      'dietaryOptionsSection',
      'generalServicesSection',
      'icingOptionsSection',
      'flavourOptionsSection',
    ],
    dietaryOptionsSection: {
      dairyFree: false,
      glutenFree: false,
      halal: false,
      kosher: false,
      nutFree: false,
      vegan: false,
      vegetarian: false,
    } as Record<Required<ISupplierDetailCake>['dietaryOptions'][number], false>,
    generalServicesSection: {
      bespokeDesign: false,
      cakeAccessories: false,
      cupcakes: false,
      delivery: false,
      lastMinuteOrders: false,
      physicalShopStudio: false,
      preWeddingConsultation: false,
      setUp: false,
      sheetCakes: false,
      tastings: false,
      tieredCakes: false,
      treatsAndFavours: false,
      donuts: false,
      brownies: false,
      cheeseBoardPlatter: false,
      macarons: false,
    } as Record<Required<ISupplierDetailCake>['generalServices'][number], false>,
    icingOptionsSection: {
      buttercream: false,
      fondant: false,
      naked: false,
      semiNaked: false,
    } as Record<Required<ISupplierDetailCake>['icingOptions'][number], false>,
    flavourOptionsSection: {
      berries: false,
      carrot: false,
      chocolate: false,
      coffee: false,
      cookiesCream: false,
      fruitCake: false,
      lemon: false,
      saltedCaramel: false,
      otherFlavour: false,
      redVelvet: false,
      raspberryWhiteChocolate: false,
      vanilla: false,
    } as Record<Required<ISupplierDetailCake>['flavourOptions'][number], false>,
  },
  catering: {
    renderSections: ['dietaryOptionsSection', 'foodTypeSection', 'generalServicesSection'],
    dietaryOptionsSection: {
      dairyFree: false,
      glutenFree: false,
      halal: false,
      kosher: false,
      nutFree: false,
      vegan: false,
      vegetarian: false,
    } as ISupplierCatering['dietaryOptionsSection'],
    foodTypeSection: {
      asianCuisine: false,
      barCocktailService: false,
      caribbeanCuisine: false,
      chineseJapaneseCuisine: false,
      hogRoast: false,
      latinSouthAmericanCuisine: false,
      middleEasternCuisine: false,
      westernEuropeanCuisine: false,
    } as ISupplierCatering['foodTypeSection'],
    generalServicesSection: {
      alcoholProvision: false,
      bartending: false,
      buffetStyle: false,
      canapes: false,
      cleanUp: false,
      foodVan: false,
      lastMinuteOrders: false,
      linenProvided: false,
      marqueeCatering: false,
      midnightTreats: false,
      minimumGuestNumbers: false,
      mobileBarServices: false,
      offSitePreparation: false,
      physicalKitchenPremises: false,
      preWeddingConsultation: false,
      seatedMeal: false,
      setUp: false,
      sharingStyle: false,
      silverService: false,
      staffProvided: false,
      tablesAndChairsProvided: false,
      tablewareProvided: false,
      tastings: false,
      weddingCake: false,
    } as ISupplierCatering['generalServicesSection'],
  },
  decoration: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      arcadeGameHire: false,
      balloons: false,
      barHire: false,
      bunting: false,
      candelabras: false,
      candles: false,
      chairCovers: false,
      cleanUp: false,
      completeStylingService: false,
      confetti: false,
      dancefloorHire: false,
      decorativeHireProps: false,
      delivery: false,
      diningFurnitureHire: false,
      generator: false,
      giantLetters: false,
      greeneryHire: false,
      heating: false,
      lastMinuteOrders: false,
      lighting: false,
      loungeFurnitureHire: false,
      luxuryToilets: false,
      microphones: false,
      portaloos: false,
      preWeddingConsultation: false,
      projector: false,
      setUp: false,
      showroom: false,
      soundPaHire: false,
    } as ISupplierDecoration['productsServicesSection'],
  },
  dress: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      accessories: false,
      alterations: false,
      appointmentOnly: false,
      bags: false,
      bespokeDressmaker: false,
      bridalBoutique: false,
      bridesmaidDresses: false,
      delivery: false,
      dressDesigner: false,
      dressHire: false,
      dryCleaning: false,
      flowerGirlDresses: false,
      hatsFascinators: false,
      highStreetRetailer: false,
      lastMinuteOrders: false,
      lingerie: false,
      motherOfTheBrideDresses: false,
      onlineShop: false,
      physicalShopStudio: false,
      preWeddingConsultation: false,
      shoes: false,
      tiaras: false,
      veils: false,
      weddingDresses: false,
    } as ISupplierDress['productsServicesSection'],
  },
  entertainment: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      bouncyCastleHire: false,
      candyCarts: false,
      caricaturists: false,
      casinos: false,
      childrenEntertainment: false,
      chocolateFountains: false,
      danceLessons: false,
      dancers: false,
      doves: false,
      facePaint: false,
      fireworks: false,
      hotTubHire: false,
      iceSculptures: false,
      lawnGames: false,
      livePainting: false,
      magicians: false,
      photoAndVideoBooth: false,
      preWeddingConsultation: false,
    } as ISupplierEntertainment['productsServicesSection'],
  },
  florist: {
    renderSections: ['productsServicesSection', 'floristStylesSection', 'floristEcoOptionsSection'],
    productsServicesSection: {
      aisleDecorations: false,
      bouquetPreservation: false,
      bouquets: false,
      buttonholes: false,
      centerpieces: false,
      chuppah: false,
      cleanUp: false,
      corsages: false,
      delivery: false,
      driedFlowers: false,
      flowerConfetti: false,
      flowerCrowns: false,
      flowerGirlBaskets: false,
      freshFlowers: false,
      greeneryHire: false,
      lastMinuteOrders: false,
      minimumOrder: false,
      onlineShop: false,
      physicalShopStudio: false,
      preWeddingConsultation: false,
      setUp: false,
      silkFlowers: false,
      vases: false,
      weddingArches: false,
      tablescaping: false,
    } as Record<Required<ISupplierDetailFlorist>['productsServices'][number], false>,
    floristStylesSection: {
      bohemian: false,
      classic: false,
      modern: false,
      natural: false,
      romantic: false,
      rustic: false,
      vintage: false,
      whimsical: false,
    } as Record<Required<ISupplierDetailFlorist>['styleOptions'][number], false>,
    floristEcoOptionsSection: {
      biodegradable: false,
      foamFree: false,
      localSourcing: false,
      seasonalSourcing: false,
      toxicChecmicalFree: false,
    } as Record<Required<ISupplierDetailFlorist>['ecoOptions'][number], false>,
  },
  jewellery: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      accessories: false,
      bespokeDesigns: false,
      broaches: false,
      cufflinks: false,
      delivery: false,
      engagementRings: false,
      engraving: false,
      fittings: false,
      gemstones: false,
      gifts: false,
      gold: false,
      highEndRetailer: false,
      highStreetRetailer: false,
      jewellery: false,
      lastMinuteOrders: false,
      onlineShop: false,
      physicalShopStudio: false,
      platinum: false,
      preWeddingConsultation: false,
      silver: false,
      watches: false,
      weddingRings: false,
    } as ISupplierJewellery['productsServicesSection'],
  },
  marquee: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      carpet: false,
      chairs: false,
      clearCeiling: false,
      dancefloor: false,
      divideAndRevealCurtain: false,
      flooring: false,
      frameClearspanMarquees: false,
      generator: false,
      heating: false,
      lastMinuteOrders: false,
      lighting: false,
      lining: false,
      luxuryToilets: false,
      otherFurniture: false,
      peakedHatMarquees: false,
      portaloos: false,
      preWeddingConsultation: false,
      starlightLining: false,
      stretchedMarquee: false,
      tables: false,
      tipisWigwams: false,
      traditionalCanvasPoleMarquees: false,
    } as ISupplierMarquee['productsServicesSection'],
  },
  menswear: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      accessories: false,
      bespokeTailoring: false,
      blackTie: false,
      delivery: false,
      highStreetRetailer: false,
      kilts: false,
      lastMinuteOrders: false,
      loungeSuits: false,
      morningSuits: false,
      onlineShop: false,
      pageboyAttire: false,
      physicalShopStudio: false,
      preWeddingConsultation: false,
      shoes: false,
      suitHire: false,
      ties: false,
      waistcoats: false,
    } as ISupplierMenswear['productsServicesSection'],
  },
  music: {
    renderSections: [
      'additionalServicesSection',
      'businessTypeSection',
      'instrumentsSection',
      'musicStyleSection',
    ],
    additionalServicesSection: {
      dancefloorHire: false,
      discoBall: false,
      discoLighting: false,
      karaoke: false,
      learnNewSongs: false,
      mcAvailable: false,
      noiseLimiterExperience: false,
      paSystem: false,
      preWeddingConsultation: false,
      smokeMachine: false,
      soundEquipment: false,
      takeRequests: false,
    } as ISupplierMusic['additionalServicesSection'],
    businessTypeSection: {
      agency: false,
      choir: false,
      disco: false,
      dj: false,
      ensembleGroup: false,
      functionBand: false,
      jazzBand: false,
      otherTypeOfPerformer: false,
      otherTypeOfPerformerDetails: '',
      soloMusician: false,
      soloSinger: false,
    } as ISupplierMusic['businessTypeSection'],
    instrumentsSection: {
      bagpipes: false,
      brass: false,
      churchBells: false,
      completeBand: false,
      drums: false,
      femaleVocals: false,
      guitar: false,
      harp: false,
      maleVocals: false,
      organ: false,
      otherInstruments: false,
      otherInstrumentsDetails: '',
      piano: false,
      strings: false,
      violin: false,
      winds: false,
    } as ISupplierMusic['instrumentsSection'],
    musicStyleSection: {
      acoustic: false,
      ceilidhIrishTraditional: false,
      ceremonyMusic: false,
      classical: false,
      drinksCocktailsMusic: false,
      funk70SDisco: false,
      jazz: false,
      latinSalsa: false,
      otherStyleOfMusic: false,
      otherStyleOfMusicDetails: '',
      popRock: false,
      rapBeatbox: false,
      ratPackSwing: false,
      receptionMusic: false,
      soulMotownRAndB: false,
      swingJive: false,
      tribute: false,
    } as ISupplierMusic['musicStyleSection'],
  },
  photo: {
    renderSections: ['photographyStyleSection', 'productsServicesSection'],
    photographyStyleSection: {
      artistic: false,
      dramatic: false,
      firstLookPhotographer: false,
      modern: false,
      reportageDocumentary: false,
      traditionalClassic: false,
    } as ISupplierPhoto['photographyStyleSection'],
    productsServicesSection: {
      backUpEquipment: false,
      bridalPrepShots: false,
      digitalEditingAirbrushing: false,
      flexibleHoursOnTheDay: false,
      imagesOnDvd: false,
      imagesOnUsb: false,
      largeGroupShots: false,
      lastMinuteOrders: false,
      onlineProofing: false,
      onlineShop: false,
      personalOnlineGallery: false,
      photoBoothAvailable: false,
      photoPrinting: false,
      physicalShopStudio: false,
      preWeddingConsultation: false,
      preWeddingVenueVisit: false,
      secondShooterAvailable: false,
      shootWithFilm: false,
      stayThroughDancing: false,
      stayThroughSpeeches: false,
      thankYouCardsPhotoStationery: false,
      videographyAvailable: false,
      weddingAlbums: false,
    } as ISupplierPhoto['productsServicesSection'],
  },
  planners: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      celebrantOfficiant: false,
      fullServicePlanning: false,
      inboundDestinationWeddingSpecialist: false,
      lastMinuteOrders: false,
      marqueeWeddingSpecialist: false,
      onTheDayWeddingCoordination: false,
      outboundDestinationWeddingSpecialist: false,
      physicalShopStudio: false,
      preWeddingConsultation: false,
      speechWriter: false,
      toastmasterMasterOfCeremony: false,
      vocalCoach: false,
      weekOfWeddingPlanning: false,
    } as ISupplierPlanner['productsServicesSection'],
  },
  stationery: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      albumsGuestbooks: false,
      bespokeDesign: false,
      catalogueSupplier: false,
      diyInvitations: false,
      engravedStationery: false,
      freeSamples: false,
      handCalligraphy: false,
      handmade: false,
      laserCutStationery: false,
      lastMinuteOrders: false,
      letterpressStationery: false,
      onlineInvitations: false,
      onlineShop: false,
      physicalShopStudio: false,
      pocketfoldStationery: false,
      preWeddingConsultation: false,
      printing: false,
      programsPlaceCardsMenus: false,
      recycledPaperOptions: false,
      saveTheDatesThankYouNotes: false,
      signs: false,
      tablePlan: false,
      weddingInvitations: false,
    } as ISupplierStationery['productsServicesSection'],
  },
  transport: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      americanClassic: false,
      astonMartin: false,
      availableForPhotoShoot: false,
      beauford: false,
      bentley: false,
      busAndCoachHire: false,
      camperVans: false,
      chauffeurService: false,
      daimler: false,
      doubleDeckerBus: false,
      ferrari: false,
      helicopter: false,
      horseAndCarriage: false,
      jaguar: false,
      lamborghini: false,
      lastMinuteOrders: false,
      londonCab: false,
      miniCooper: false,
      modernLuxuryCars: false,
      motorbikeSideCar: false,
      preWeddingConsultation: false,
      rollsRoyce: false,
      selfDriveAllowed: false,
      showroom: false,
      sportsCars: false,
      stretchLimos: false,
      taxis: false,
      vintageClassicCars: false,
    } as ISupplierTransport['productsServicesSection'],
  },
  venue: {
    renderSections: ['ceremonyReceptionSection', 'foodDrinkSection', 'tags', 'venueSection'],
    ceremonyReceptionSection: {
      churchWithinWalkingDistance: false,
      confettiPermitted: false,
      fireworksPermitted: false,
      inHouseDancefloor: false,
      outdoorCeremonyLicence: false,
      soundSystemAvailable: false,
      weddingLicence: false,
    } as ISupplierVenue['ceremonyReceptionSection'],
    foodDrinkSection: {
      alcoholLicense: false,
      asianCatering: false,
      bar: false,
      byobPermitted: false,
      corkageCharge: false,
      externalCateringPermitted: false,
      halalCatering: false,
      inHouseCatering: false,
      inHouseWineList: false,
      kosherCatering: false,
    } as ISupplierVenue['foodDrinkSection'],
    overviewSection: {
      alcoholLicense: false,
      bestOfBritain: false,
      exclusiveUse: false,
      hasAccommodation: false,
      lateNightExtensionAvailable: false,
      weddingLicence: false,
    },
    venueFeatures: {
      churchWithinWalkingDistance: false,
      disabledAccess: false,
      marqueePermitted: false,
      onsiteParking: false,
      petFriendly: false,
      transportation: false,
      wifi: false,
    },
    venueSection: {
      ballroom: false,
      bridalChangingFacilities: false,
      cityView: false,
      disabledAccess: false,
      exclusiveUse: false,
      gradeListedBuilding: false,
      inHouseWeddingCoordinator: false,
      landscapedGardens: false,
      marqueePermitted: false,
      onsiteParking: false,
      openFlamePermitted: false,
      outdoorSpace: false,
      petFriendly: false,
      spaOnsite: false,
      waterView: false,
    } as ISupplierVenue['venueSection'],
    venueType: {
      barn: false,
      castle: false,
      cityHotelCityVenue: false,
      conferenceCentre: false,
      countryHouseManorHouse: false,
      cruiseBoatYacht: false,
      gardenOutdoor: false,
      golfCourse: false,
      hotel: false,
      museumAttraction: false,
      otherVenueType: false,
      placeOfWorship: false,
      pubRestaurant: false,
      resort: false,
      rooftop: false,
      sportingVenueStadium: false,
      statelyHome: false,
      townHallRegistryOffice: false,
      uniqueVenueType: false,
      warehouseFactory: false,
      winery: false,
    } as ISupplierVenue['venueType'],
    venueStyle: {
      affordable: false,
      alternative: false,
      asian: false,
      beach: false,
      blankCanvas: false,
      casual: false,
      classic: false,
      formal: false,
      greatViews: false,
      historic: false,
      intimate: false,
      lovelyGrounds: false,
      luxury: false,
      modern: false,
      outdoor: false,
      romantic: false,
      rustic: false,
      stylish: false,
      unique: false,
      unusual: false,
      urban: false,
    } as ISupplierVenue['venueStyle'],
  },
  video: {
    renderSections: ['productsServicesSection'],
    productsServicesSection: {
      audioRetouching: false,
      backUpEquipment: false,
      bridalPrepShots: false,
      completeFootageOnDvdUsb: false,
      digitalEditing: false,
      dolliesAndGliders: false,
      droneFootage: false,
      engagementShoots: false,
      flexibleHoursOnTheDay: false,
      lastMinuteOrders: false,
      longEditMoreThan1Hour: false,
      marryoke: false,
      musicalOverlay: false,
      photoBoothAvailable: false,
      physicalShopStudio: false,
      preWeddingConsultation: false,
      sameDayHighlightFilm: false,
      secondShooterAvailable: false,
      shortEditMoreThan15Minutes: false,
      stayThroughDancing: false,
      stayThroughSpeeches: false,
      steadicam: false,
      weddingHighlightsFilm: false,
    } as ISupplierVideo['productsServicesSection'],
  },
};

// Additional bridesite only props / overrides
const extraProps = {
  email: true,
  photosCount: 0,
  visibleInMainSearch: false,
};

// TODO: fix something about ISupplier, it's not returning a valid ISupplier type
const createSupplier = (slug: Slug) => ({
  ...(extraFilterProps[slug] as unknown as ISupplier),
  ...extraProps,
});

export default createSupplier;
