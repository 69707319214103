import { getTestVariant } from 'lib/ab-tests/selectors';
import { ABTestData } from 'lib/ab-tests/types';
import { IApplicationState } from 'lib/types';
import { useABTestIntegration } from '../../hooks/use-ab-test-integration';

const testData: ABTestData = {
  id: 'LIVE-19024_FloristQuizSearchIntercept',
  name: 'LIVE-19024 FloristQuizSearchIntercept',
  description: 'Intercept search redirect to display florist quiz',
};

export const getIsFloristQuizSearchInterceptEnabled = (state: IApplicationState) =>
  getTestVariant(state, testData.id) !== 'control';

export const useABTest_FloristQuizSearchIntercept = () => {
  useABTestIntegration({ testId: testData.id });
};
