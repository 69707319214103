import {
  changeEnquiryContext,
  checkEnquiryDetail,
  confirmEnquiryFormError,
  enquiryEditToggleDatepicker,
  enquiryFormToggle,
  enquiryFormToggleEdit,
  enquiryRequiredDataToggle,
  fetchEnquiryConfirmationSupplier,
  fetchEnquiryConfirmationSupplierSuccess,
  fetchEnquiryCount,
  fetchEnquiryCountSuccess,
  fetchEnquiryDates,
  fetchEnquiryDatesSuccess,
  openVenueConfirmationPopup,
  resetEnquiryForm,
  saveEnquiryFieldsToDatabaseError,
  sendEnquiry,
  sendEnquirySuccess,
  setEnquiryFormEditState,
  setEnquiryFormField,
  setEnquirySupplier,
  setInfoProps,
  setTriedToContact,
  showEnquiriesUserLimitModal,
  toggleEnquiryConfirmationScreen,
  toggleEnquiryMessage,
  toggleMultipleEnquiry,
  validateEnquiryFormError,
} from 'lib/enquiries/actions';

export enum EnquiriesActionTypes {
  CHANGE_ENQUIRY_CONTEXT = '[Enquiries] Change enquiry context',
  CHECK_ENQUIRY_DETAIL = '[Enquiries] Check enquiry detail',
  CONFIRM_ENQUIRY_FORM_ERROR = '[Enquiries] confirm enquiry error',
  CONFIRM_ENQUIRY_FORM_START = '[Enquiries] Confirm enquiry form start',
  CONFIRM_ENQUIRY_FORM_SUCCESS = '[Enquiries] Confirm enquiry form success',
  DISMISS_ENQUIRY_FORM_ERROR = '[Enquiries] dismiss enquiry form error',
  ENQUIRY_CLICKED_EDIT = '[Enquiries] Enquiry click edit',
  ENQUIRY_FORM_TOGGLE = '[Enquiries] Enquiry form toggle',
  ENQUIRY_FORM_TOGGLE_DATEPICKER = '[Enquiries] Enquiry form toggle datepicker',
  ENQUIRY_FORM_TOGGLE_EDIT = '[Enquiries] Enquiries form toggle edit',
  ENQUIRY_FORM_SET_EDIT_STATE = '[Enquiries] Enquiry form set edit state',
  ENQUIRY_REQUIRED_DATA_TOGGLE = '[Enquiries] Enquiry required data toggle',
  FETCH_ENQUIRY_CONFIRMATION_SUPPLIER_START = '[Enquiries] Fetch enquiry confirmation supplier start',
  FETCH_ENQUIRY_CONFIRMATION_SUPPLIER_SUCCESS = '[Enquiries] Fetch enquiry confirmation supplier FS success',
  ON_SUPPLIER_ENQUIRY_INTERACT_ANALYTICS = '[Analytics] [Enquiries] On supplier enquiry interact',
  RESET_ENQUIRY_FORM = '[Enquiries] Reset enquiry form',
  SAVE_ENQUIRY_DATEPICKER_DATE = '[Enquiries] Save enquiry datepicker date',
  SAVE_ENQUIRY_FIELDS_TO_DATABASE_ERROR = '[Enquiries] Save enquiry fields to database error',
  SAVE_ENQUIRY_FIELDS_TO_DATABASE_START = '[Enquiries] Save enquiry fields to database start',
  SAVE_ENQUIRY_FIELDS_TO_DATABASE_SUCCESS = '[Enquiries] Save enquiry fields to database success',
  SEND_ENQUIRY_ERROR = '[Enquiries] Send enquiry error',
  SEND_ENQUIRY_START = '[Enquiries] Send enquiry start',
  SEND_ENQUIRY_SUCCESS = '[Enquiries] Send enquiry success',
  SEND_ENQUIRY_SUCCESS_ANALYTICS = '[Enquiries][Analytics] Send enquiry success analytics',
  SET_ENQUIRY_FORM_FIELD = '[Enquiries] Set enquiry form field',
  SET_ENQUIRY_SUPPLIER = '[Enquiries] Set enquiry supplier',
  SET_TRIED_TO_CONTACT = '[Enquiries] Set tried to contact',
  TOGGLE_ENQUIRY_CONFIRMATION = '[Enquiries] Toggle enquiry confirmation',
  TOGGLE_ENQUIRY_CONFIRMATION_SCREEN = '[Enquiries] Toggle enquiry confirmation screen',
  TOGGLE_ENQUIRY_MESSAGE = '[Enquiries] Toggle enquiry message',
  VALIDATE_ENQUIRY_FORM_ERROR = '[Enquiries] Validate enquiry form error',
  FETCH_ENQUIRY_DATES = '[Enquiries] Fetch enquiry dates',
  FETCH_ENQUIRY_DATES_SUCCESS = '[Enquiries] Fetch enquiry dates success',
  FETCH_ENQUIRY_COUNT = '[Enquiries] Fetch enquiry count',
  FETCH_ENQUIRY_COUNT_SUCCESS = '[Enquiries] Fetch enquiry count success',
  CLEAR_ENQUIRY_COUNT = '[Enquiries] Clear enquiry count',
  ENQUIRY_COUNT_VIEWED_ANALYTICS = '[Enquiries][Analytics] Viewed urgent message',
  ENQUIRY_COUNT_CLOSED_ANALYTICS = '[Enquiries][Analytics] Clicked to dismiss urgent message',
  SHOW_ENQUIRIES_USER_LIMIT_MODAL = '[Enquiries] Show enquiries user limit modal',
  TOGGLE_MULTIPLE_ENQUIRY = '[Enquiries] Toggle multiple enquiries',
  OPEN_CONFIRMATION_MODAL = '[Enquiries] Open venue confirmation popup',
  SET_LOGGED_OUT_ENQUIRY_TRIGGERED = '[Enquiries] Set logged out enquiry triggered',
  SET_INFO_PROPS = '[Enquiries] Set info props',
}

export type IFetchEnquiryConfirmationSupplier = ReturnType<typeof fetchEnquiryConfirmationSupplier>;

export type ISendEnquiryAction = ReturnType<ReturnType<typeof sendEnquiry>>;

export type ISendEnquirySuccessAction = ReturnType<ReturnType<typeof sendEnquirySuccess>>;

export type IToggleEnquiryConfirmationScreenAction = ReturnType<
  typeof toggleEnquiryConfirmationScreen
>;

export type ISetEnquiryFormFieldAction = ReturnType<ReturnType<typeof setEnquiryFormField>>;

export type ISetTriedToContactAction = ReturnType<typeof setTriedToContact>;

export type ICheckEnquiryDetailAction = ReturnType<typeof checkEnquiryDetail>;

export type IToggleEnquiryMessageAction = ReturnType<typeof toggleEnquiryMessage>;

export type IValidateEnquiryFormErrorAction = ReturnType<typeof validateEnquiryFormError>;

export type ISaveEnquiryFieldsToDatabaseErrorAction = ReturnType<
  ReturnType<typeof saveEnquiryFieldsToDatabaseError>
>;

export type IConfirmEnquiryFormErrorAction = ReturnType<ReturnType<typeof confirmEnquiryFormError>>;

export type ISetEnquirySupplierAction = ReturnType<typeof setEnquirySupplier>;

export type IChangeEnquiryContextAction = ReturnType<typeof changeEnquiryContext>;

export type IEnquiryFormToggleAction = ReturnType<ReturnType<typeof enquiryFormToggle>>;
export type IEnquiryRequiredDataToggleAction = ReturnType<
  ReturnType<typeof enquiryRequiredDataToggle>
>;

export type IResetEnquiryFormAction = ReturnType<ReturnType<typeof resetEnquiryForm>>;

export type IFetchEnquiryConfirmationSupplierSuccessAction = ReturnType<
  ReturnType<typeof fetchEnquiryConfirmationSupplierSuccess>
>;

export type IEnquiryFormToggleEditAction = ReturnType<ReturnType<typeof enquiryFormToggleEdit>>;

export type IEnquiryEditToggleDatepickerAction = ReturnType<
  ReturnType<typeof enquiryEditToggleDatepicker>
>;

export type IFetchEnquiryDates = ReturnType<typeof fetchEnquiryDates>;

export type IFetchEnquiryDatesSuccess = ReturnType<typeof fetchEnquiryDatesSuccess>;

export type IFetchEnquiryCount = ReturnType<typeof fetchEnquiryCount>;

export type IFetchEnquiryCountSuccess = ReturnType<typeof fetchEnquiryCountSuccess>;

export type IShowEnquiriesUserLimitModalAction = ReturnType<typeof showEnquiriesUserLimitModal>;

export type IToggleMultipleEnquiry = ReturnType<typeof toggleMultipleEnquiry>;

export type IOpenVenueConfirmationPopup = ReturnType<typeof openVenueConfirmationPopup>;

export type IEnquiryFormSetEditState = ReturnType<typeof setEnquiryFormEditState>;

export type IEnquirySetInfoProps = ReturnType<typeof setInfoProps>;
