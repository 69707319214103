import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  heading: FelaCSS;
}

const styles = (): IStyles => ({
  heading: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderColor: colors.space15,
    fontDefaultSemiBold: 16,
    paddingVertical: 6,
    flexDirection: 'row',
  },
});

export default styles;
