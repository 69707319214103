import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useABTestIntegration } from 'lib/ab-tests/hooks/use-ab-test-integration';
import { getTestVariant } from 'lib/ab-tests/selectors';
import { IApplicationState } from 'lib/types';

const testId = 'LIVE-19141_ForceIEAndFRPhoneNumberEnquiry';

export const countriesWithForcePhoneNumberABTest = [CountryCodes.IE, CountryCodes.DE];

export const getVariant_ForcePhoneNumber = (state: IApplicationState) =>
  getTestVariant(state, testId);

export const useABTest_ForcePhoneNumber = () => {
  const market = useMarket();
  const countryCode = market.country;
  const triggerCondition = countriesWithForcePhoneNumberABTest.includes(countryCode);

  useABTestIntegration({ testId, triggerCondition });
};
