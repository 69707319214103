import {
  getVariant_WeddingEstimatePriceFilter,
  useShouldTriggerWeddingEstimatePriceFilterTest,
} from 'lib/ab-tests/tests/LIVE-21226_WeddingEstimatePriceFilter';
import { useSelector } from 'lib/utils';

export const useShowWeddingEstimatePriceFilter = () => {
  const testVariant = useSelector(getVariant_WeddingEstimatePriceFilter);
  const shouldShowWeddingEstimatePriceFilter = useShouldTriggerWeddingEstimatePriceFilterTest();

  return shouldShowWeddingEstimatePriceFilter && testVariant === '1';
};
