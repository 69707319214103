import React from 'react';
import { useSelector } from 'react-redux';
import { SearchByNameModalProps } from 'components/search/searchbar2/search-by-name-modal/search-by-name-modal';
import { IApplicationState } from 'lib/types';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const SearchByNameModal = lazyComponent<SearchByNameModalProps>(
  () => import('components/search/searchbar2/search-by-name-modal/search-by-name-modal'),
);

interface SearchModalLazyProps {}

const SearchModalLazy: React.FC<SearchModalLazyProps> = () => {
  const show = useSelector<IApplicationState, boolean>((state) => state.ui.searchByNameModal.show);
  if (!show) return null;

  return <SearchByNameModal show={show} />;
};

export default React.memo(SearchModalLazy);
