import { useTranslation } from 'next-i18next';
import React from 'react';
import { Box, Button } from '@bridebook/ui';
import { IconCrossBold } from '@bridebook/ui/src/icons/dynamic';
import FilterReset from '../filter-reset/filter-reset';
import componentStyles from './filter-top-bar.style';

type ButtonProps = Parameters<typeof Button>[0];

interface IProps {
  close: ButtonProps['onClick'];
}

const FilterTopBar = ({ close }: IProps) => {
  const { t } = useTranslation('search');
  const styles = componentStyles();

  return (
    <Box style={styles.wrapper}>
      <Box style={styles.resetWrap}>
        <FilterReset />
      </Box>
      <Box as="h4" style={styles.heading}>
        {t('common:filter')}
      </Box>
      <Box style={styles.closeWrap}>
        <Button theme="ghost" color="space40" icon={<IconCrossBold width={16} />} onClick={close} />
      </Box>
    </Box>
  );
};

export default FilterTopBar;
