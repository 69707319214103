import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { env } from 'lib/env';
import { UrlHelper } from 'lib/url-helper';

type MetaRouterScriptProps = { isCordova: boolean };

export const MetaRouterScript = React.memo(function MetaRouterScript({
  isCordova,
}: MetaRouterScriptProps) {
  const analyticsScript = `!function(){var e=window.analytics=window.analytics||[];if(!e.initialize)if(e.invoked)window.console&&console.error&&console.error("MetaRouter snippet included twice.");else{e.invoked=!0,e.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"],e.factory=function(t){return function(){var o=Array.prototype.slice.call(arguments);return o.unshift(t),e.push(o),e}};for(var t=0;t<e.methods.length;t++){var o=e.methods[t];e[o]=e.factory(o)}e.load=function(t,o){var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="/mr${
    env.LIVE ? '' : '-dev'
  }.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n),e._loadOptions=o},e.SNIPPET_VERSION="4.13.1";`;
  const sessionId = '';
  const segmentId = env.SEGMENTID;
  return (
    <Script
      id={'metarouter_script'}
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `${analyticsScript}analytics.load("${segmentId}");var a=window.location.href,n=window.location.pathname;e.page(n,{title:n,path:n,url:a,mobileApp:${isCordova},sessionId:"${sessionId}"})}}();`,
      }}
    />
  );
});

/* Appsflyer smart banner */
const EXCLUDE_APP_BANNER_PAGES = [
  '/_error',
  UrlHelper.signup,
  UrlHelper.onboarding,
  UrlHelper.changePassword,
  UrlHelper.inboxPanel(),
  `${UrlHelper.search}/`,
];

export const AppBannerScript = React.memo(function AppBannerScript({
  showBanner,
}: {
  showBanner: boolean;
}) {
  const { asPath } = useRouter();
  const market = useMarket();
  const appBannerDisabled = EXCLUDE_APP_BANNER_PAGES.some((page) =>
    asPath.startsWith(`/${market.prefix}${page}`),
  );
  const AFReadyInitValue = typeof window !== 'undefined' && !!window.AF;
  const [isAFReady, setIsAFReady] = useState(AFReadyInitValue);

  useEffect(() => {
    if (isAFReady) {
      const AF = window.AF;
      appBannerDisabled
        ? AF('banners', 'hideBanner')
        : AF('banners', 'showBanner', { bannerContainerQuery: '#appsflyer-container' });
    }
  }, [appBannerDisabled, isAFReady]);

  if (!showBanner) return null;

  return (
    <Script
      id={'app_banner_script'}
      onLoad={() => setIsAFReady(true)}
      strategy="lazyOnload"
      src="/scripts/app-banner.js"
    />
  );
});
