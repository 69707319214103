import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { EnquiryIntents, IUISupplier } from '@bridebook/toolbox/src/types';

interface ICreateEnquiryArgs {
  message: string;
  userId: string;
  profileId: string;
  intent: EnquiryIntents;
  budgetMatch: boolean;
  contactedSupplier: IUISupplier | ISupplier;
  captchaToken: string;
  source?: string;
}

const createEnquiry = ({
  message,
  userId,
  profileId,
  intent,
  budgetMatch,
  contactedSupplier,
  captchaToken,
  source,
}: ICreateEnquiryArgs) => {
  const type = Array.isArray(contactedSupplier.type)
    ? contactedSupplier.type?.[0]
    : contactedSupplier.type;

  if (!type) {
    throw new Error('createEnquiry: no slug.');
  }

  return authenticatedFetch(`/api/enquiries/send-enquiry`, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      userId,
      profileId,
      message,
      intent,
      budgetMatch,
      supplierId: contactedSupplier.id,
      captchaToken,
      source,
    }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error('createEnquiry: API error');
    }

    return res.json();
  });
};

export default createEnquiry;
