import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetSearchFilters, toggleFilters, updateSearchUrl } from 'lib/search/actions';

/**
 * Resets all filters applied
 */
export const useResetFilters = () => {
  const location = useRouter();
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(resetSearchFilters());
    dispatch(updateSearchUrl({ location }));
    dispatch(toggleFilters());
  }, [dispatch, location]);
};
