import Router from 'next/router';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { getI18n } from 'lib/i18n/getI18n';
import { getEnquiryCTAText } from 'lib/utils/get-enquiry-cta-text';

const logSentryError = (translation: string, locale?: string) => {
  SentryMinimal().captureMessage(`incorrect translation: ${translation}, for locale: ${locale}`);
};

const getVenueMessage = (translation: string, locale?: string) => {
  logSentryError(translation, locale);
  switch (locale) {
    case 'de':
      return 'Hallo,\n\nWir sind an Ihrer Location interessiert! Könnten Sie uns bitte mitteilen, ob Sie zu unserem Wunschtermin verfügbar sind, und uns Ihre Broschüre und/oder Preisinformationen zukommen lassen?\n\nVielen Dank!';
    case 'fr':
      return 'Bonjour,\n\nVotre lieu nous intéresse ! Pouvez-vous partager votre disponibilité autour de notre date idéale, ainsi que votre brochure et/ou vos informations tarifaires ?\n\nMerci !';
    default:
      return "Hi,\n\nWe're interested in your venue! Please can you share your availability around our ideal date, plus your brochure and/or pricing information?\n\nThank you!";
  }
};

const getDefaultMessage = (translation: string, locale?: string) => {
  logSentryError(translation, locale);
  switch (locale) {
    case 'de':
      return 'Hallo,\n\nWir sind an Ihren Dienstleistungen interessiert! Könnten Sie uns bitte Ihre Verfügbarkeit rundum unser Datum sowie weitere Informationen mitteilen?\n\nVielen Dank!';
    case 'fr':
      return "Bonjour,\n\nNous sommes intéressés par vos services ! Pouvez-vous partager votre disponibilité autour de notre date, plus toute information supplémentaire ?\n\nMerci d'avance !";
    default:
      return "Hi,\n\nWe're interested in your services! Please could you share your availability around our date, plus any additional information?\n\nThank you!";
  }
};

const getShowround1Message = (translation: string, locale?: string) => {
  logSentryError(translation, locale);
  switch (locale) {
    case 'de':
      return 'Hallo,\n\nWir sind an Ihrer Location interessiert! Können Sie uns mitteilen, wann es möglich wäre, eine Besichtigung zu organisieren?\n\nVielen Dank!';
    case 'fr':
      return "Bonjour,\n\nNous sommes intéressés par votre lieu de réception ! Quelles seraient vos disponibilités pour organiser une visite ?\n\nMerci d'avance !";
    default:
      return "Hi,\n\nWe're interested in your venue! Can you let us know when it would be possible to organise a showround?\n\nThank you!";
  }
};

const getShowround2Message = (translation: string, locale?: string) => {
  logSentryError(translation, locale);
  switch (locale) {
    case 'de':
      return 'Hallo,\n\nWir ziehen Sie als unsere Hochzeitslocation in Betracht. Können Sie mir mitteilen, wann es möglich wäre, eine Besichtigung zu organisieren?\n\nVielen Dank!';
    case 'fr':
      return "Bonjour,\n\nNous sommes intéressés par votre lieu de réception pour notre mariage ! Quelles seraient vos disponibilités pour organiser une visite ?\n\nMerci d'avance !";
    default:
      return 'Hi,\n\nWe are considering you as a venue for our wedding! Can you let me know when it would be possible to organise a showround?\n\nThank you!';
  }
};

const getVideoTourMessage = (translation: string, locale?: string) => {
  logSentryError(translation, locale);
  switch (locale) {
    case 'de':
      return 'Hallo,\n\nWir interessieren uns für Ihre Location! Können Sie uns bitte mitteilen, wann eine LIVE-Videotour organisiert werden kann?';
    case 'fr':
      return "Bonjour,\n\nNous sommes intéressés par votre lieu de réception pour notre mariage ! Quelles seraient vos disponibilités pour organiser une visite guidée en ligne ?\n\nMerci d'avance !";
    default:
      return 'Hi,\n\nWe’re interested in your venue! Please can you let us know when it would be possible to organise a LIVE video tour?';
  }
};

const getFairMessage = (translation: string, locale?: string) => {
  logSentryError(translation, locale);
  switch (locale) {
    case 'de':
      return 'Hallo,\n\nWir sind interessiert an Ihrer bevorstehenden Veranstaltung. Können Sie einen Platz für uns reservieren?\n\nDanke!';
    case 'fr':
      return "Bonjour,\n\nNous sommes intéressés par votre évènement ! Serait-il possible de réserver une place pour nous deux ?\n\nMerci d'avance !";
    default:
      return 'Hi,\n\nWe’re interested in your upcoming event. Please could you reserve a place for us?\n\nThank you!';
  }
};

// below function checks if translations passed as a parameter is a proper translation, or just a key
// if translation is the proper one we are entering the "default" case
// however if the translation is returned as key and not a value we are calling the correct function to return hardcoded translation and logging an error message in sentry
// link to bug: https://bridebook.atlassian.net/browse/LIVE-19103
const getCorrectedTranslation = (translation: string) => {
  const locale = Router.locale;
  switch (translation) {
    case 'enquiryMessage.venue': {
      return getVenueMessage(translation, locale);
    }
    case 'enquiryMessage.default': {
      return getDefaultMessage(translation, locale);
    }
    case 'enquiryMessage.showround1': {
      return getShowround1Message(translation, locale);
    }
    case 'enquiryMessage.showround2': {
      return getShowround2Message(translation, locale);
    }
    case 'enquiryMessage.videoTour': {
      return getVideoTourMessage(translation, locale);
    }
    case 'enquiryMessage.fair': {
      return getFairMessage(translation, locale);
    }
    default: {
      return translation;
    }
  }
};

/**
 * Returns a default message depending on a place where enquiry modal was opened from
 */
export const getDefaultEnquiryMessage = (isVenue: boolean, context?: string) => {
  const i18n = getI18n();
  const defaultMessage = getCorrectedTranslation(i18n.t('enquiries:enquiryMessage.default'));

  const defaultVenueMessage = getCorrectedTranslation(i18n.t('enquiries:enquiryMessage.venue'));

  switch (context) {
    case 'supplierProfile_actionsSection_showround':
      return getCorrectedTranslation(i18n.t('enquiries:enquiryMessage.showround1'));
    case 'supplierProfile_showround':
      return getCorrectedTranslation(i18n.t('enquiries:enquiryMessage.showround2'));
    case 'supplierProfile_video_tour':
      return getCorrectedTranslation(i18n.t('enquiries:enquiryMessage.videoTour'));
    case 'shortlist_button':
    case 'supplierProfile_supplier_location':
    case 'supplierProfile_email':
    case 'supplierProfile_faq':
      return defaultMessage;
    case 'supplierProfile_fairs':
      return getCorrectedTranslation(i18n.t('enquiries:enquiryMessage.fair'));
    case 'search_button':
    case 'sideForm':
    case 'supplierProfile_topButton':
    case 'supplierProfile_bottomButton':
    case 'supplierProfile_sideBar':
    case 'supplierProfile_pricingSection':
    case 'supplierProfile_topButtonDownloadable':
    case 'supplierProfile_details':
    case 'supplierProfile_awards':
    case 'supplierProfile_preferredSuppliers':
    case 'supplierProfile_actionsSection_contact':
    default:
      return isVenue ? defaultVenueMessage : defaultMessage;
  }
};

/**
 * Returns copy for "send enquiry" CTA and a disclaimer
 */
export const getDefaultMapping = (isVenue: boolean) => {
  const i18n = getI18n();
  const defaultContextItem = {
    disclaimer: i18n.t('enquiries:disclaimer_1'),
    CTA: getEnquiryCTAText(isVenue),
  };

  const venueShowroundContextItem = {
    disclaimer: i18n.t('enquiries:disclaimer_2'),
    CTA: i18n.t('enquiries:cta.requestShowround'),
  };

  const defaultDisclaimer = getI18n().t('enquiries:disclaimer_3');

  const defaultContactCTAText = isVenue
    ? getI18n().t('enquiries:cta.contactVenue')
    : getI18n().t('enquiries:cta.contactSupplier');

  return {
    shortlist_button: {
      disclaimer: defaultDisclaimer,
      CTA: defaultContactCTAText,
    },
    search_button: defaultContextItem,
    linkedSupplier_weddingConfirmationPopup: {
      disclaimer: i18n.t('enquiries:disclaimer_1'),
      CTA: i18n.t('enquiries:cta.requestAvailability'),
    },
    sideForm: defaultContextItem,
    supplierProfile_topButton: defaultContextItem,
    supplierProfile_bottomButton: defaultContextItem,
    supplierProfile_sideBar: defaultContextItem,
    supplierProfile_getAQuote: defaultContextItem,
    supplierProfile_pricingSection: {
      disclaimer: i18n.t('enquiries:disclaimer_4'),
      CTA: i18n.t('common:cta.requestQuote'),
    },
    supplierProfile_fairs: {
      disclaimer: defaultDisclaimer,
      CTA: i18n.t('enquiries:cta.bookYourPlace'),
    },
    supplierProfile_topButtonDownloadable: defaultContextItem,
    supplierProfile_details: defaultContextItem,
    supplierProfile_actionsSection_contact: {
      disclaimer: defaultDisclaimer,
      CTA: defaultContactCTAText,
    },
    supplierProfile_awards: defaultContextItem,
    supplierProfile_email: {
      disclaimer: defaultDisclaimer,
      CTA: i18n.t('enquiries:cta.sendEmail'),
    },
    supplierProfile_faq: {
      disclaimer: defaultDisclaimer,
      CTA: i18n.t('enquiries:cta.askQuestion'),
    },
    supplierProfile_location: {
      disclaimer: defaultDisclaimer,
      CTA: defaultContactCTAText,
    },
    supplierProfile_preferredSuppliers: defaultContextItem,
    supplierProfile_actionsSection_showround: venueShowroundContextItem,
    supplierProfile_showround: venueShowroundContextItem,
    supplierProfile_video_tour: {
      disclaimer: defaultDisclaimer,
      CTA: i18n.t('enquiries:cta.requestLiveTour'),
    },
  };
};

export type IGetDefaultMappingReturnType = ReturnType<typeof getDefaultMapping>;
