import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  buttonWrap: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: colors.space04,
  },

  buttonWrap: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
    paddingTop: '16px',
    width: '100%',
    flexShrink: 0,

    supportsSafeAreaInset: {
      paddingBottom: 'calc(16px + env(safe-area-inset-bottom))',
    },
  },
});

export default styles;
