import React, { FunctionComponent, PropsWithChildren } from 'react';
import Box from '../../../components/fela/Box';
import { IStylingProps } from '../../../themes/types';
import { MessageTheme } from '../persistent-message/types';
import { messageContainerStyle } from './message-container.style';

export interface Props extends IStylingProps {
  theme?: MessageTheme;
}

const MessageContainer: FunctionComponent<PropsWithChildren<Props>> = ({
  theme,
  children,
  ...restProps
}) => (
  <Box style={messageContainerStyle({ theme })} {...restProps}>
    {children}
  </Box>
);

export default MessageContainer;
