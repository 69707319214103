import { contains, filter } from 'ramda';
import React, { useCallback } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import type { Slug } from '@bridebook/toolbox/src/types';
import { Box } from '@bridebook/ui';
import ContentAccordion from 'app-shared/components/content-accordion/content-accordion';
import { useUpdateFilters } from 'components/search/hooks';
import { toggleSearchFilterAccordion, updateFiltersCount } from 'lib/search/actions';
import { getFilters, getFiltersSections } from 'lib/search/selectors';
import { SectionFiltersType, UpdateSearchFilters } from 'lib/search/types';
import { getSectionByFilterName } from 'lib/search/utils';
import getActiveFilters from 'lib/search/utils/get-active-filters';
import getSectionTitle from 'lib/search/utils/get-section-title';
import msg, { MsgKey } from 'lib/supplier/msg';
import { IApplicationState } from 'lib/types';
import FilterCheckbox from '../filter-checkbox/filter-checkbox';
import componentStyles from './filter-section.style';

const storeEnhancer = connect(
  (state: IApplicationState) => ({
    filters: getFilters(state),
    filterAccordions: state.search.filterAccordions,
    filterSections: getFiltersSections(state),
  }),
  {
    updateFiltersCount,
    toggleSearchFilterAccordion,
  },
);

interface IProps extends ConnectedProps<typeof storeEnhancer> {
  category: Slug;
  fields: Array<any>;
  sectionName: string;
  searchFilters: Array<SectionFiltersType>;
  index?: number;
}

const FilterSection = ({
  category,
  filters,
  fields,
  sectionName,
  searchFilters,
  filterAccordions,
  toggleSearchFilterAccordion,
  index,
  filterSections,
}: IProps) => {
  const isVenue = category === 'venue';
  const noTopBorder = index === 0 && !isVenue;
  const updateFilters = useUpdateFilters();
  const actionCallback = useCallback(
    (data: Omit<UpdateSearchFilters, 'location'>) => {
      updateFilters({ ...data, skipAnalytics: true });
    },
    [updateFilters],
  );

  // get filter section filter data field name
  const filterDataName = filter(
    (item) => item.field === sectionName,
    filterSections[category].sections,
  );

  const filterDataFieldName = filterDataName?.[0]?.field || '';
  const activeFiltersCount = getActiveFilters(filterDataFieldName, filters);
  const alwaysOpenedSection = contains(sectionName, ['overviewSection']);
  const styles = componentStyles();

  return (
    <ContentAccordion
      {...{ noTopBorder }}
      accordions={filterAccordions}
      name={sectionName}
      toggleContentAccordion={toggleSearchFilterAccordion}
      text={
        <Box style={styles.heading}>
          {getSectionTitle(sectionName)}
          {activeFiltersCount > 0 && <Box as="span">&nbsp;({activeFiltersCount})</Box>}
        </Box>
      }
      leaveBorder
      containerHeight={68}
      openStatic={!isVenue || alwaysOpenedSection}
      noSidePaddings
      noRoundBorder
      noBgColor>
      <Box style={styles.wrapper}>
        <Box style={styles.filters}>
          {fields.map((filter) => (
            <Box style={styles.checkboxWrap} key={`filter-key-box-${filter}`}>
              <FilterCheckbox
                key={`fcb-${filter}`}
                field={getSectionByFilterName(searchFilters, filter)}
                prop={filter}
                title={msg[filter as MsgKey]?.()}
                id={filter}
                action={actionCallback}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </ContentAccordion>
  );
};

export default storeEnhancer(FilterSection);
