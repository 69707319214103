import { getI18n } from 'lib/i18n/getI18n';
import { getEnquiryCTAText } from 'lib/utils/get-enquiry-cta-text';
import { testedEnquiryCtaList } from '../tested-enquiry-cta-list';
import { EnquiryFormContextType, EnquiryFormRecordType } from '../types';
import {
  IGetDefaultMappingReturnType,
  getDefaultEnquiryMessage,
  getDefaultMapping,
} from './context-mapping';

export const getEnquiryFormContext = (
  contextName: keyof IGetDefaultMappingReturnType,
  isVenue: boolean,
  multipleEnquiry: boolean,
): EnquiryFormContextType => {
  const contextBySupplierType: IGetDefaultMappingReturnType = getDefaultMapping(isVenue);

  const enquiryContextTexts = contextBySupplierType[contextName];
  const enquiryCTAText = getEnquiryCTAText(isVenue);
  const isTestedCTA = testedEnquiryCtaList.includes(contextName);
  const i18n = getI18n();

  if (!contextName || !enquiryContextTexts) {
    return {
      contextName: '',
      buttonCaption: enquiryCTAText,
      disclamer: i18n.t(
        'enquiries:disclaimerDefault',
        'We’ll pass the information to the supplier so they can contact you with a bespoke proposal!',
        {
          count: multipleEnquiry ? 2 : 1,
          defaultValue_plural:
            'We’ll pass the information to the suppliers so they can contact you with a bespoke proposal!',
        },
      ),
    };
  }

  return {
    contextName,
    buttonCaption: isTestedCTA ? enquiryCTAText : enquiryContextTexts.CTA,
    disclamer: enquiryContextTexts.disclaimer,
  };
};

export const getEnquiryFormContextMessage = ({
  contextName,
  isVenue,
  enquiryForm,
}: {
  contextName: string;
  isVenue: boolean;
  enquiryForm?: EnquiryFormRecordType;
}) => {
  const defaultMessage = getDefaultEnquiryMessage(isVenue, contextName);

  if (enquiryForm) {
    const {
      dirty,
      fields: { message },
    } = enquiryForm;

    if (dirty) {
      return message || defaultMessage;
    }
  }

  return defaultMessage;
};
