import { useTranslation } from 'next-i18next';
import { includes } from 'ramda';
import { Box, Radio } from '@bridebook/ui';
import { IGuestOption } from 'lib/search/get-guest-filter-options';
import useGuestsFilter from 'lib/search/hooks/use-guests-filter';
import componentStyles from './guests-filter.style';

const GuestsFilter = () => {
  const { t } = useTranslation('search');
  const { optionItems, checkedOptions, onFilterApply, onFilterClear } =
    useGuestsFilter('filtersModal');

  const styles = componentStyles();

  const handleChange = (option: IGuestOption) => {
    option.key === checkedOptions[0]?.key
      ? onFilterClear?.()
      : onFilterApply(option.minValue, true);
  };

  return (
    <>
      <Box as="h2" style={styles.heading}>
        {t('range.label.diningCapacity')}
      </Box>
      <Box style={styles.wrapper}>
        {optionItems.map((option) => (
          <Box key={option.key} mb={6} mr={4}>
            <Radio
              name="guest-filter"
              option={{
                label: option.primaryText,
                value: option.key,
              }}
              checked={includes(option, checkedOptions)}
              onClick={() => handleChange(option)}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default GuestsFilter;
