import { FelaCSS, FelaCSSWithCustomSelectors, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  heading: FelaCSS;
  radioGroup: FelaCSS;
  radioWrapper: (isChecked?: boolean) => FelaCSSWithCustomSelectors;
}

const styles = (): IStyles => ({
  wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  heading: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderColor: colors.space15,
    fontDefaultSemiBold: 16,
    marginTop: 0,
    marginBottom: 6,
    paddingTop: 6,
    flexDirection: 'row',
  },
  radioGroup: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
    marginBottom: 5,
  },
  radioWrapper: (isChecked) => ({
    color: isChecked ? colors.space : colors.space60,
    '> label': {
      fontDefaultSemiBold: 14,
    },
  }),
});

export default styles;
