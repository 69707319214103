import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Auth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  onAuthStateChanged,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  verifyPasswordResetCode,
} from 'firebase/auth';
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  serverTimestamp,
  setLogLevel,
  terminate,
  waitForPendingWrites,
} from 'firebase/firestore';
import { TFunction } from 'next-i18next';
import Router from 'next/router';
import { values } from 'ramda';
import { MutableRefObject } from 'react';
import { TStore } from 'store/store';
import { Users, Weddings } from '@bridebook/models';
import { getApp, getFirestore } from '@bridebook/models/source/firebase/firestore';
import { IUser } from '@bridebook/models/source/models/Users.types';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import {
  authenticatedFetch,
  authenticatedPOST,
} from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { User as FirebaseUser } from '@firebase/auth';
import { clearIndexedDbPersistence } from '@firebase/firestore';
import {
  AuthStateType,
  LOGOUT_DELAY_TIME,
  TAuthRequest,
  TRedirectAfterLoginOrSignup,
} from 'components/auth/auth-context';
import { acceptedCollaborationInvite } from 'lib/access-control';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import {
  AuthSessionData,
  LoginOrSignupRequest,
  LoginOrSignupResult,
} from 'lib/api/authenticate/types';
import {
  changePasswordError,
  changePasswordSuccess,
  isSignedOutFirebase,
  redirectAfterAuthSuccess,
  saveNextPathAfterOnboarding,
  signInError,
  signInOrSignUpError,
  signOutCompleted,
  signUpError,
  triggerAuthAnalytics,
  userSetupCompleted,
  userSetupStarted,
} from 'lib/auth/actions';
import { AuthBridebookError, AuthProviders, ICredentialsFields } from 'lib/auth/types';
import { checkExistingProviders, getAuthProvider } from 'lib/auth/utils/auth-provider-utils';
import { fetchBudgetData } from 'lib/auth/utils/fetch-budget-data';
import { fetchChecklistData } from 'lib/auth/utils/fetch-checklist-data';
import { fetchGuestlistData } from 'lib/auth/utils/fetch-guestlist-data';
import { fetchShortlistData } from 'lib/auth/utils/fetch-shortlist-data';
import { getDocFromCacheOrServer } from 'lib/auth/utils/get-doc-from-cache-or-server';
import { getRedirect } from 'lib/auth/utils/redirects';
import { updateLocale } from 'lib/auth/utils/update-locale';
import { validateEmailAndPassword } from 'lib/auth/utils/validate-email-pass';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { changeLanguage } from 'lib/change-language/utils/change-language';
import { onFirebaseBudget } from 'lib/budget/actions/on-listener';
import { fetchTasksInitialSuccess, fetchUserChecklistSuccess } from 'lib/checklist/actions';
import { env } from 'lib/env';
import { onFirebaseGuestlist } from 'lib/guestlist/actions';
import { getI18n } from 'lib/i18n/getI18n';
import { getCookieLocale } from 'lib/i18n/utils/get-cookie-locale';
import nativeLogin from 'lib/mobile-app/utils/native-social-auth';
import { fetchRealWeddings } from 'lib/real-weddings/slice';
import { fetchUserShortlistSuccess } from 'lib/shortlist/actions';
import { mapWeddingSuppliersSnapshotToShortlistData } from 'lib/shortlist/utils/map-wedding-suppliers-snapshot-to-shortlist-data';
import { ROOT_TODAY_TASK_FLOW_ID } from 'lib/task/today-task-flow';
import { TodayTaskStorage } from 'lib/task/today-task-storage';
import { BridebookError } from 'lib/types';
import { GuestsOnlyUrls, UrlHelper } from 'lib/url-helper';
import {
  markUserAsDeletedError,
  markUserAsDeletedStart,
  markUserAsDeletedSuccess,
} from 'lib/users/actions/mark-user-as-deleted';
import { isCordovaApp, mapCleanTimestamps } from 'lib/utils';
import { getAsPathNormalized } from 'lib/utils/url';
import validate from 'lib/validate';

interface ISuccessCallbackParams {
  token: string;
}

const earlyInitializeBasedOnlyOnUserId = (userId: string) => {
  SentryMinimal().setUser({ id: userId });
  const todayTaskStorage = TodayTaskStorage({ id: ROOT_TODAY_TASK_FLOW_ID }, userId);
  todayTaskStorage.migrate();
};

const handleUserLoaded = async (user: IUser) => {
  const cookieLocale = getCookieLocale();
  const userLocale = user.l10n.locale;
  if (!cookieLocale || cookieLocale !== userLocale) {
    await changeLanguage(userLocale);
  }
};
/**
 * Redirects user either to the home page or onboarding based on the fact
 * whether the user just created an account or logged in
 */
export const handleRedirectAfterAuth = async (store: TStore, authSessionData: AuthSessionData) => {
  const { isCollaborator, signedUp, weddingData, userData } = authSessionData;
  const dispatch = store.dispatch;
  try {
    const market = gazetteer.getMarketByCountry(weddingData.l10n.country);
    market.locale = userData.l10n.locale;

    const { pathname, query } = Router;
    const asPath = getAsPathNormalized();
    const state = store.getState();
    const {
      app: { previousQuery },
      enquiries: { loggedOutEnquiryTriggered },
    } = state;
    const { path, redirect, next } = getRedirect({
      query,
      asPath,
      signedUp,
      pathname,
      previousQuery,
      market,
      loggedOutEnquiryTriggered,
      ...(authSessionData.isCollaborator
        ? {
            isCollaborator: true,
            redirectURLAfterLoginOrSignup: authSessionData.redirect,
          }
        : {
            isCollaborator: false,
          }),
    });

    if (next) {
      dispatch(saveNextPathAfterOnboarding(encodeURI(next)));
    }

    redirect &&
      (await updateLocale(market, path, () => {
        window.scrollTo(0, 0);
      }));
    dispatch(redirectAfterAuthSuccess());
  } catch (error) {
    dispatch({
      type: 'REDIRECT_AFTER_AUTH_ERROR',
      payload: { error },
    });
    SentryMinimal().captureException(error, {
      tags: {
        source: 'Auth context',
        feature: 'redirectAfterLoginOrSignup',
      },
      extra: {
        signedUp,
        isCollaborator,
      },
    });
  }
};

/**
 * Initializes the user and the services and managers such as sentry or today's task local storage manager.
 * It runs only once per app start (this includes hard page reload as well)
 */
export const handleInitialize = (
  store: TStore,
  loginOrRegisterInProgress: MutableRefObject<boolean>,
  authStateRef: MutableRefObject<AuthStateType>,
  handleSetAuthState: (value: AuthStateType) => void,
  firebaseApp?: FirebaseApp,
) => {
  const dispatch = store.dispatch;
  try {
    onAuthStateChanged(getAuth(firebaseApp), async (firebaseUser) => {
      const path = window.location.pathname;
      const i18n = getI18n();

      if (loginOrRegisterInProgress.current || authStateRef.current.isInitialized) {
        return;
      }
      if (firebaseUser) {
        dispatch(userSetupStarted());
        earlyInitializeBasedOnlyOnUserId(firebaseUser.uid);
        let weddingData: IWedding | null | undefined = null;

        const userDataRef = Users._.getById(firebaseUser.uid).reference;
        const userData = (await getDocFromCacheOrServer(userDataRef)).data();
        if (userData && userData.weddings) {
          const weddingDataRef = Weddings._.getById(
            userData.weddings[userData.weddings.length - 1],
          ).reference;
          weddingData = (await getDocFromCacheOrServer(weddingDataRef)).data();
        }

        if (!weddingData || !userData) {
          SentryMinimal().captureMessage(
            '[Auth context] User not found in DB or user does not have an active wedding',
            { extra: { userData, weddingData } },
          );
          dispatch(toggleSnackbar('alert', i18n.t('auth:noWeddingFound.contactSupport')));
          setTimeout(() => {
            handleLogout(store, false, firebaseApp);
          }, LOGOUT_DELAY_TIME);
          return;
        }
        await handleUserLoaded(userData);
        dispatch(userSetupCompleted(firebaseUser, userData, weddingData));

        dispatch(fetchRealWeddings(weddingData.id ? 'venue' : 'near'));

        await fetchChecklistData(dispatch, weddingData.id, weddingData.l10n.country);
        await fetchShortlistData(dispatch, weddingData.id);
        await fetchGuestlistData(dispatch, weddingData.id);
        await fetchBudgetData(dispatch, weddingData.id);

        handleSetAuthState({
          isInitialized: true,
          isAuthenticated: true,
          user: userData,
          wedding: weddingData,
        });

        const isSignupPage = path.includes(UrlHelper.signup);
        if (isSignupPage) {
          const response = await authenticatedPOST<any, { found: boolean }>(
            ApiEndpoint.userSession,
          );
          if (response.found) {
            await handleRedirectAfterAuth(store, {
              signedUp: false,
              isCollaborator: false,
              weddingData,
              userData,
            });
          } else {
            dispatch(toggleSnackbar('alert', i18n.t('auth:sessionExpired')));
            await handleLogout(store, false, firebaseApp);
          }
          return;
        }

        if (path !== '/') {
          const marketFromUrl = gazetteer.getMarketByURL(path, CountryCodes.GB);
          if (weddingData.l10n.country !== marketFromUrl.country) {
            await handleRedirectAfterAuth(store, {
              signedUp: false,
              isCollaborator: false,
              weddingData,
              userData,
            });
          }
        }
      } else {
        dispatch(isSignedOutFirebase());
        const response = await authenticatedPOST<any, { found: boolean }>(ApiEndpoint.userSession);
        if (response.found) {
          dispatch(toggleSnackbar('alert', i18n.t('auth:sessionExpired')));
          const result = await fetch(ApiEndpoint.logout, {
            method: 'POST',
            credentials: 'same-origin',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          });
          if (result.status === 200) {
            const isGuestOnlyUrl = Object.values(GuestsOnlyUrls).some((url) => path.includes(url));
            if (!isGuestOnlyUrl || (isCordovaApp() && path !== '/')) {
              await Router.push(UrlHelper.login);
            }
          }
        }

        handleSetAuthState({
          isInitialized: true,
          isAuthenticated: false,
          user: null,
          wedding: null,
        });
      }
    });
  } catch (error) {
    SentryMinimal().captureException(error, {
      tags: {
        source: 'Auth context',
        feature: 'initialize',
      },
    });
  }
};

/**
 * Calls an authenticate endpoint that depending on the payload creates / reads / modifies user and wedding data
 * from / in firebase whether the user just created an account / logged in into existing account or joins a wedding as
 * a collaborator with new or existing account.
 * On success, the most recent user and wedding data is saved in store and in indexDB to speed up the "initialize" process
 * the next time the app is started.
 * On fail, no data is modified / created in the database, new account is deleted and in the case of the existing one it is logged out.
 * The notice is also displayed to the user that something went wrong and suggestion on what to do next (contact support or try again)
 */
export const handleSetupUserAfterLoginOrSignup = async (
  store: TStore,
  firebaseUser: FirebaseUser,
  handleSetAuthState: (value: AuthStateType) => void,
  logout: (logOutAllDevices?: boolean) => Promise<void>,
  countryCode: CountryCodes,
  locale: string,
  nonceId?: string,
  nonceSecret?: string,
) => {
  const dispatch = store.dispatch;
  dispatch(userSetupStarted());
  earlyInitializeBasedOnlyOnUserId(firebaseUser.uid);
  const state = store.getState();
  const pathName = state.app.pathname;

  const fetchBody: LoginOrSignupRequest = {
    countryCode,
    locale,
    nonceId,
    nonceSecret,
  };

  const responseRaw = await authenticatedFetch<LoginOrSignupResult>(ApiEndpoint.authenticate, {
    method: 'POST',
    credentials: 'same-origin',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(fetchBody),
  });

  const response = await responseRaw.json();

  if (response.status === 'success') {
    const {
      userData,
      weddingData,
      weddingSuppliersData,
      countryTasksData,
      weddingTasksData,
      weddingGuestsData,
      weddingCostsData,
    } = response;

    await handleUserLoaded(userData);
    dispatch(userSetupCompleted(firebaseUser, userData, weddingData));
    dispatch(triggerAuthAnalytics(pathName, firebaseUser.uid, response.signedUp, userData));

    // @ts-ignore FIXME
    dispatch(fetchTasksInitialSuccess(countryTasksData));
    dispatch(
      fetchUserChecklistSuccess({
        result: mapCleanTimestamps(weddingTasksData),
        source: 'server',
      }),
    );

    const shortlisted = await mapWeddingSuppliersSnapshotToShortlistData(weddingSuppliersData);
    dispatch(fetchUserShortlistSuccess({ shortlisted }));
    dispatch(onFirebaseGuestlist(values(mapCleanTimestamps(weddingGuestsData))));
    dispatch(onFirebaseBudget(mapCleanTimestamps(weddingCostsData)));
    if (response.isCollaborator) {
      dispatch(acceptedCollaborationInvite());
    }

    handleSetAuthState({
      isInitialized: true,
      isAuthenticated: true,
      user: userData,
      wedding: weddingData,
    });
  }

  if (response.status === 'fail' && response.flowType === 'signup') {
    SentryMinimal().captureMessage('[Auth context] Signup failed', {
      tags: { source: 'Auth context', feature: 'setupUserAfterLoginOrSignup' },
      extra: { responseData: response },
    });
    const error: AuthBridebookError = {
      prop: 'email',
      name: '',
      code: 'custom/api-authenticate-failed',
      message: '',
    };
    dispatch(signUpError(error, { email: firebaseUser.email ?? 'no email provided' }));
    await deleteUser(firebaseUser);
    return;
  }

  if (response.status === 'fail' && response.flowType === 'login') {
    SentryMinimal().captureMessage('[Auth context] Login failed', {
      tags: { source: 'Auth context', feature: 'setupUserAfterLoginOrSignup' },
      extra: { responseData: response },
    });
    const error: BridebookError = {
      prop: 'email',
      name: '',
      code: 'custom/api-authenticate-failed',
      message: '',
    };
    dispatch(signInError(error));
    await logout();
  }

  if (response.status === 'fail' && response.flowType === 'uninitialized') {
    SentryMinimal().captureMessage('[Auth context] Api failed before the flow type was checked', {
      tags: { source: 'Auth context', feature: 'setupUserAfterLoginOrSignup' },
      extra: { responseData: response },
    });
    const error: BridebookError = {
      prop: 'email',
      name: '',
      code: 'custom/api-authenticate-failed',
      message: '',
    };
    dispatch(signInOrSignUpError(error));
    await logout();
    return;
  }

  return response;
};

/**
 * Handles login and register with auth providers on cordova devices
 */
export const handleCordovaOauth = (
  providerId: AuthProviders,
  firebaseAuth: Auth,
): Promise<UserCredential> =>
  new Promise<UserCredential>((resolve, reject) => {
    const successCallback = ({ token }: ISuccessCallbackParams) => {
      let credential;

      switch (providerId) {
        case AuthProviders.FACEBOOK:
          credential = FacebookAuthProvider.credential(token);
          break;
        case AuthProviders.GOOGLE:
          credential = GoogleAuthProvider.credential(token);
          break;
        case AuthProviders.APPLE:
          credential = new OAuthProvider('apple.com').credential({
            idToken: token,
          });
          break;
        default:
          reject('Not supported provider');
          return;
      }

      signInWithCredential(firebaseAuth, credential)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    };

    const errorCallback = (error: any) => {
      reject(error);
    };

    nativeLogin({
      providerId,
      successCallback,
      errorCallback,
    });
  });

/**
 * Logs into an existing firebase-auth account with email and password provider only
 */
export const handleLogin = async (
  store: TStore,
  authRequest: TAuthRequest,
  loginOrRegisterInProgress: MutableRefObject<boolean>,
  setupUserAfterLoginOrSignup: (
    firebaseUser: FirebaseUser,
    countryCode: CountryCodes,
    locale: string,
  ) => Promise<LoginOrSignupResult | undefined>,
) => {
  const dispatch = store.dispatch;
  try {
    loginOrRegisterInProgress.current = true;
    if (authRequest.type === 'password') {
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);
      await validateEmailAndPassword(validate, authRequest);
      await checkExistingProviders(auth, authRequest.email, AuthProviders.PASSWORD);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        authRequest.email,
        authRequest.password,
      );

      return await setupUserAfterLoginOrSignup(
        userCredential.user,
        authRequest.countryCode,
        authRequest.locale,
      );
    }
  } catch (e) {
    if (authRequest.type === 'password') {
      dispatch(signInError(e, AuthProviders.PASSWORD));
    }
  } finally {
    loginOrRegisterInProgress.current = false;
  }
};

/**
 * Clears all data stored in indexDB and terminates the firestore connection
 * after the user logged out
 */
export const handleLogout = async (
  store: TStore,
  logOutAllDevices?: boolean,
  firebaseApp?: FirebaseApp,
) => {
  const dispatch = store.dispatch;
  try {
    const user = getAuth(firebaseApp).currentUser;
    if (!user) {
      throw new Error('No user in state');
    }
    const firestore = getFirestore();
    await waitForPendingWrites(firestore);
    await signOut(getAuth(firebaseApp));
    await terminate(firestore);
    await clearIndexedDbPersistence(firestore);

    if (!firebaseApp) {
      firebaseApp = initializeApp(env.FIREBASE);
    }

    /**
     * To enable fetching from cache after logging out and back in, we need to re-initialize the offline Firebase persistence after it has been cleared.
     */
    initializeFirestore(firebaseApp, {
      experimentalAutoDetectLongPolling: true,
      experimentalLongPollingOptions: {
        timeoutSeconds: 25,
      },
      ignoreUndefinedProperties: true,
      localCache: persistentLocalCache({
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
        tabManager: persistentMultipleTabManager(),
      }),
    });

    setLogLevel('error');

    await fetch(ApiEndpoint.logout, {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        logOutAllDevices,
      }),
    });
    dispatch(signOutCompleted());
  } catch (error) {
    SentryMinimal().captureException(error, {
      tags: {
        source: 'Auth context',
        feature: 'logout',
      },
      extra: {
        logOutAllDevices,
      },
    });
  }
};

/**
 * Creates a new firebase-auth account with email and password or social providers
 * or
 * logs into an existing firebase-auth account with social providers
 */
export const handleRegister = async (
  store: TStore,
  authRequest: TAuthRequest,
  loginOrRegisterInProgress: MutableRefObject<boolean>,
  setupUserAfterLoginOrSignup: (
    firebaseUser: FirebaseUser,
    countryCode: CountryCodes,
    locale: string,
  ) => Promise<LoginOrSignupResult | undefined>,
  cordovaOauth: (providerId: AuthProviders, firebaseAuth: Auth) => Promise<UserCredential>,
  firebaseApp?: FirebaseApp,
) => {
  const dispatch = store.dispatch;
  try {
    loginOrRegisterInProgress.current = true;
    if (authRequest.type === 'password') {
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);
      await validateEmailAndPassword(validate, authRequest);
      await checkExistingProviders(auth, authRequest.email, AuthProviders.PASSWORD);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        authRequest.email,
        authRequest.password,
      );

      return await setupUserAfterLoginOrSignup(
        userCredential.user,
        authRequest.countryCode,
        authRequest.locale,
      );
    }
    if (authRequest.type === 'social') {
      const auth = getAuth(firebaseApp);
      const provider = getAuthProvider(authRequest.provider);

      const userCredential = isCordovaApp()
        ? await cordovaOauth(authRequest.provider, auth)
        : await signInWithPopup(auth, provider);
      return await setupUserAfterLoginOrSignup(
        userCredential.user,
        authRequest.countryCode,
        authRequest.locale,
      );
    }
  } catch (e) {
    if (authRequest.type === 'password') {
      dispatch(signUpError(e, authRequest));
    } else {
      dispatch(signInError(e, authRequest.provider));
    }
  } finally {
    loginOrRegisterInProgress.current = false;
  }
};

/**
 * Marks user as deleted in the firebase and logs the user out
 */
export const handleDeleteAccount = async (
  store: TStore,
  logout: (logOutAllDevices?: boolean) => Promise<void>,
  t: TFunction,
) => {
  const dispatch = store.dispatch;
  const state = store.getState();
  const userId = state.users.user?.id;
  if (!userId) throw new Error('Missing userId');
  if (userId) {
    try {
      dispatch(markUserAsDeletedStart);
      await Users._.getById(userId).set({
        deleteAt: serverTimestamp(),
      });
      dispatch(markUserAsDeletedSuccess);
      setTimeout(() => {
        logout(true);
      }, LOGOUT_DELAY_TIME);
    } catch (error) {
      dispatch(toggleSnackbar('alert', t('settings:deleteAccount.errorSnackbarMessage')));
      dispatch(markUserAsDeletedError);
      SentryMinimal().captureException(error, {
        tags: {
          source: 'Auth context',
          feature: 'deleteAccount',
        },
      });
    }
  }
};

/**
 * [coupleside and cms] Changes user password in firebase-auth and on success
 * logs in the coupleside users or redirects to the continueUrl if the url was passed (mainly cms login page url for cms users)
 */
export const handleChangePassword = async (
  store: TStore,
  actionCode: string,
  authFields: ICredentialsFields,
  login: (authRequest: TAuthRequest) => Promise<LoginOrSignupResult | undefined>,
  redirectAfterLoginOrSignup: TRedirectAfterLoginOrSignup,
  countryCode: CountryCodes,
  locale: string,
  continueUrl?: string,
) => {
  const dispatch = store.dispatch;
  try {
    const firebaseApp = getApp();
    const defaultAuth = getAuth(firebaseApp);
    const actualEmail = await verifyPasswordResetCode(defaultAuth, actionCode);
    await confirmPasswordReset(defaultAuth, actionCode, authFields.password);

    const fields: TAuthRequest = {
      type: 'password',
      email: actualEmail,
      password: authFields.password,
      countryCode,
      locale,
    };

    if (continueUrl) {
      window.location.replace(continueUrl);
    } else {
      const result = await login(fields);
      if (result && result.status === 'success') {
        dispatch(changePasswordSuccess(fields));
        await redirectAfterLoginOrSignup(result);
      }
    }
  } catch (error) {
    dispatch(changePasswordError(authFields, error));
  }
};
