import { isNil } from 'ramda';
import React, { ReactNode } from 'react';
import { IStylingProps } from '../../../themes/types';
import Box from '../../fela/Box';
import IconChevron from '../../svg/icon-chevron';
import componentStyles from './accordion-head.style';

export interface SharedTypes {
  opened?: boolean;
  disabled?: boolean;
  hideArrow?: boolean;
  leaveBorder?: boolean;
  noSidePaddings?: boolean;
  containerHeight?: number;
  noBgColor?: boolean;
  noRoundBorder?: boolean;
  noTopBorder?: boolean;
  onClick?: Function;
  openStatic?: boolean;
  horizontalMargins?: boolean;
  noFixedHeight?: boolean;
  arrowStartDown?: boolean;
}

export interface IProps extends SharedTypes, IStylingProps {
  id?: string;
  text: string | ReactNode;
  itemAmount: string | number | null;
  headCustom?: ReactNode;
}

const AccordionHead = ({
  id,
  onClick,
  text,
  headCustom,
  itemAmount,
  opened,
  disabled,
  hideArrow = false,
  openStatic,
  leaveBorder,
  noSidePaddings,
  containerHeight,
  noBgColor,
  noRoundBorder,
  noTopBorder,
  horizontalMargins,
  noFixedHeight,
  arrowStartDown,
  ...restProps
}: IProps) => {
  const styles = componentStyles({
    opened,
    disabled,
    hideArrow,
    leaveBorder,
    noSidePaddings,
    containerHeight,
    noBgColor,
    noRoundBorder,
    noTopBorder,
    onClick,
    openStatic,
    horizontalMargins,
    noFixedHeight,
    arrowStartDown,
  });

  return (
    <Box style={styles.wrapper} {...restProps}>
      <Box {...{ id, onClick }} style={styles.container}>
        <Box style={styles.text}>
          <Box style={isNil(itemAmount) && styles.fullWidth}>{text}</Box>
          {!isNil(itemAmount) && <Box style={styles.amount}>({itemAmount})</Box>}
        </Box>
        {!hideArrow && !openStatic && (
          <Box style={styles.arrow}>
            <IconChevron width={14} color="silver" />
          </Box>
        )}
      </Box>
      {headCustom && <Box style={styles.customWrap}>{headCustom}</Box>}
    </Box>
  );
};

export default AccordionHead;
