import { FelaCSS, spacing } from '@bridebook/ui';

interface IProps {
  isVenue: boolean;
}

interface IStyles {
  wrapper: FelaCSS;
  resetWrap: FelaCSS;
  sectionWrap: FelaCSS;
}

const styles = ({ isVenue }: IProps): IStyles => ({
  wrapper: {
    padding: `${spacing[4]} ${spacing[4]} 0 ${spacing[4]}`,
    overflowX: 'hidden',
    boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.20)',
    WebkitOverflowScrolling: 'touch',
    maxWidthSmMax: {
      paddingTop: isVenue ? 4 : 0,
    },
    minWidthSmMin: {
      maxHeight: '60vh',
      overflowY: 'auto',
      overscrollBehavior: 'none',
    },
  },

  resetWrap: {
    alignItems: 'flex-end',
  },

  sectionWrap: {
    flexShrink: 0,
  },
});

export default styles;
