import { filter, isEmpty, values } from 'ramda';
import { Dictionary } from 'ramda/tools';
import { Weddings } from '@bridebook/models';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { ISupplier as IWeddingSupplier } from '@bridebook/models/source/models/Weddings/Suppliers.types';
import { IEnrichedSupplierOutgoing } from '@bridebook/toolbox/src/enrich-suppliers/enrich-suppliers';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { IUISupplier, Slug } from '@bridebook/toolbox/src/types';
import { ICustomTarget } from '@bridebook/ui/src/types';
import { prettySupplierCategory } from 'app-shared/lib/utils';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { updateUserBudget } from 'lib/budget/actions';
import { env } from 'lib/env';
import { getI18n } from 'lib/i18n/getI18n';
import { getCountryCode } from 'lib/i18n/selectors';
import { hapticSelectFeedback } from 'lib/mobile-app/utils/haptic-feedback';
import { getOpenedSupplierDetails } from 'lib/shortlist/selectors';
import {
  BookedMethod,
  ICollaborationResponse,
  ShortlistedLocation,
  ShortlistedMethod,
  TShortlistList,
  TShortlistTab,
} from 'lib/shortlist/types';
import {
  isSupplierCollaborationAvailable,
  saveSupplierInWeddingSuppliers,
} from 'lib/shortlist/utils';
import { SupplierActions } from 'lib/supplier/actions-types';
import { isSupplierVenue } from 'lib/supplier/utils';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import { Action, IDeps, IElasticSupplier } from 'lib/types';
import { noopAction } from 'lib/utils';
import { closeBookingConfirmationModal } from 'lib/venue-confirmation/actions';
import { saveVenueLocation } from 'lib/weddings/actions';
import { ShortlistActionTypes } from './action-types';

const getBookedSnackbarMessage = () => getI18n().t('common:shortlist.snackbar.booked');

export const shortlistedSupplierAnalytics = (
  supplier: IUISupplier,
  method: ShortlistedMethod,
  shortlistedLocation?: ShortlistedLocation,
  linkedSupplierName?: string,
  linkedSupplierId?: string,
) => ({
  type: 'SHORTLISTED_SUPPLIER_ANALYTICS',
  payload: {
    method,
    supplier,
    shortlistedLocation,
    linkedSupplierName,
    linkedSupplierId,
  },
});

export const saveToShortlist =
  (
    item: IUISupplier & { booked?: boolean },
    method: ShortlistedMethod,
    shortlistedLocation?: ShortlistedLocation,
    showSnackbar: boolean = true,
    linkedSupplierName?: string,
    linkedSupplierId?: string,
  ) =>
  ({ getState, dispatch, cordovaTracker }: IDeps) => {
    const {
      shortlist: { list },
      weddings: {
        profile: { id: weddingId },
      },
      app: {
        device: { isCordova },
        pathname,
      },
    } = getState();

    const shortlistedIds = Object.keys(list);
    const isShortlisted = shortlistedIds.includes(item.id);
    // Check max limit only if supplier is not already shortlisted to allow marking as booked
    if (!isShortlisted && shortlistedIds.length >= env.SHORTLIST_USER_LIMIT) {
      return showShortlistUserLimitModal(true);
    }

    let isVenue;
    const isBooking = Boolean(item.booked);

    /** haptic feedback only when adding/removing from shortlist */
    if (!isBooking && isCordova) {
      hapticSelectFeedback();
    }
    if (item.id) {
      saveSupplierInWeddingSuppliers({ ...item, shortlisted: true }, weddingId)
        .then((wedding) => {
          // @ts-expect-error
          wedding.increment('suppliers.count');
          cordovaTracker.track(TrackingEvent.ShortlistedSupplier, item);
          dispatch(
            shortlistedSupplierAnalytics(
              item,
              method,
              shortlistedLocation,
              linkedSupplierName,
              linkedSupplierId,
            ),
          );
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.error('SHORT:ERROR', error));
    }

    if (isBooking) {
      dispatch(addedToBookedSuppliers(item));
      const { town, county, country, type } = item;
      isVenue = type === 'venue';

      if (isVenue) {
        // for bookings from supplier profile
        const tier = item.tier ?? item.tier ?? getState().supplier.premium?.tier;
        // CountryCode is only needed until we translate supplier-couple portal, as we only collaborate with UK venues for now
        const countryCode = item.countryCode ?? CountryCodes.GB;
        const supplier = {
          ...item,
          tier,
          countryCode,
        };
        if (isSupplierCollaborationAvailable(item)) {
          dispatch(toggleSupplierCollaboration({ supplier, isCollaborating: true }));
        }

        if (countryCode) {
          dispatch(
            // LIVE-18673 Keeping wedding address the same as booked supplier address
            saveVenueLocation(
              {
                ...(town && { name: town, city: town }),
                ...(county && country && { adminArea: [county, country] }),
                country: countryCode,
              },
              item.customSupplier,
            ),
          );
        }
      }
    }
    // Don't show snackbar for venue booking due to discover venue modal
    const venueBooking = isBooking && isVenue;
    const showSnackbarShortlistLink =
      shortlistedLocation !== 'shortlist' && !pathname.startsWith('/shortlist');

    if (showSnackbar && !venueBooking) {
      dispatch(
        toggleSnackbar(
          'success',
          isBooking
            ? getBookedSnackbarMessage()
            : getI18n().t('common:shortlist.snackbar.shortlisted'),
          undefined,
          showSnackbarShortlistLink
            ? {
                action: 'goToFavourites',
                actionData: {
                  supplierType: item.type,
                  supplierId: item.id,
                },
                analyticsData: {
                  category: 'Shortlist',
                  cta: 'View favourites page from favourites success banner',
                  clickedSection: 'supplierProfile',
                  clickedLocation: 'favouritesSuccessBanner',
                  supplierId: item.id,
                },
                emoji: '🎉',
              }
            : { emoji: '🎉' },
        ),
      );
    }

    return saveToShortlistEnriched(item);
  };

/** TS hell, we need another return type to satisfy types in shortlistChildAddedEpic */
export const saveToShortlistEnriched = (payload: IUISupplier & { booked?: boolean }) => ({
  type: ShortlistActionTypes.SAVE_TO_SHORTLIST,
  payload,
});

export const fetchVenueRecommendedSuppliersSuccess =
  (recommendedSuppliers: IElasticSupplier[]) => () => ({
    type: ShortlistActionTypes.FETCH_VENUE_RECOMMENDED_SUPPLIERS_SUCCESS,
    payload: recommendedSuppliers,
  });

export const removeFromShortlist =
  (
    supplier: Pick<IUISupplier, 'id' | 'type' | 'slug'> | IWeddingSupplier,
    method: string,
    shortlistedLocation?: ShortlistedLocation,
  ) =>
  ({ dispatch, getState }: IDeps): Action => {
    const {
      weddings: {
        profile: { id: weddingId },
      },
      app: {
        device: { isCordova },
      },
    } = getState();
    const { premium } = getState().supplier;

    /** Haptic feedback on mobile */
    if (isCordova) {
      hapticSelectFeedback();
    }

    if (supplier.id) {
      const wedding = Weddings._.getById(weddingId);

      wedding.Suppliers.getById(supplier.id)
        .set({ shortlisted: false, booked: false }, ['shortlisted', 'booked'])
        .then(() => {
          // @ts-expect-error
          wedding.decrement('suppliers.count');
          dispatch({
            type: 'REMOVED_SUPPLIER_FROM_SHORTLIST_ANALYTICS',
            payload: { method, supplier, shortlistedLocation },
          });
        });
    }

    const supplierType = Array.isArray(supplier.type)
      ? supplier.type[0]
      : supplier.type || (supplier as IUISupplier).slug;

    if (supplierType === 'venue') {
      dispatch(
        toggleSupplierCollaboration({
          supplier: { slug: supplierType, id: supplier.id, tier: premium?.tier } as IUISupplier,
          isCollaborating: false,
        }),
      );
    }

    dispatch(toggleSnackbar('info', getI18n().t('common:shortlist.snackbar.removed')));

    return {
      type: ShortlistActionTypes.REMOVE_FROM_SHORTLIST,
      payload: { id: supplier.id },
    };
  };

export const fetchUserShortlistSuccess = (payload: { shortlisted: TShortlistList }) => ({
  type: ShortlistActionTypes.FETCH_USER_SHORTLIST_SUCCESS,
  payload,
});

export const onRemovedSuppliersFromShortlist = (payload?: IWeddingSupplier[]) => ({
  type: ShortlistActionTypes.ON_REMOVED_SUPPLIERS_FROM_SHORTLIST,
  payload,
});

export const onSuppliersShortlistedSuccess = (
  payload: (IEnrichedSupplierOutgoing | IWeddingSupplier)[],
) => ({
  type: ShortlistActionTypes.ON_SUPPLIERS_SHORTLISTED_SUCCESS,
  payload,
});

export const addedToBookedSuppliers = (item: IUISupplier) => ({
  type: ShortlistActionTypes.ADDED_TO_BOOKED_SUPPLIERS,
  payload: { item },
});

export const shortlistBookSupplier =
  (item: IUISupplier, method: string) =>
  ({ dispatch }: IDeps) => {
    dispatch(addedToBookedSuppliers(item));
    if (isSupplierCollaborationAvailable(item)) {
      dispatch(toggleSupplierCollaboration({ supplier: item, isCollaborating: true }));
    } else {
      dispatch(toggleSnackbar('success', getBookedSnackbarMessage()));
    }
    return {
      type: ShortlistActionTypes.BOOK_SUPPLIER,
      payload: { item, method },
    };
  };

export const showUnbookSupplierWarning =
  ({ supplier, method }: { supplier: IUISupplier; method: string }) =>
  ({ getState, dispatch, warningModal }: IDeps) => {
    const state = getState();
    const countryCode = getCountryCode(state);
    const market = gazetteer.getMarketByCountry(countryCode as CountryCodes);
    const supplierCategory = prettySupplierCategory({
      slug: supplier.type,
      amount: 1,
      isLoggedIn: true,
      market,
    });
    const isVenue = isSupplierVenue(supplier);

    warningModal.toggleWarningModal({
      title: getI18n().t('shortlistModals:unbookConfirmation.title', {
        supplierCategory,
      }),
      subtitle: isVenue
        ? getI18n().t('shortlistModals:unbookConfirmation.subtitleVenue')
        : getI18n().t('shortlistModals:unbookConfirmation.subtitleDefault', {
            supplierName: supplier.name,
          }),
      type: 'confirm',
      onConfirm: () => {
        dispatch(unbookSupplier(supplier, method));
        if (isVenue) {
          dispatch(toggleSupplierCollaboration({ supplier, isCollaborating: false }));
        }
      },
      confirmText: getI18n().t('shortlistModals:unbookConfirmation.confirm'),
    });

    return noopAction();
  };

export const unbookSupplier = (item: IUISupplier, method: string) => ({
  type: ShortlistActionTypes.UNBOOK_SUPPLIER,
  payload: { item, method },
});

export const clearCustomSupplierForm = () => ({
  type: ShortlistActionTypes.CLEAR_CUSTOM_SUPPLIER_FORM,
});

export const toggleSupplierAddModal = (
  open: boolean = false,
  triggerLocation?: string,
  addToBooked?: boolean,
  defaultCategory?: Slug,
) => ({
  type: ShortlistActionTypes.TOGGLE_SUPPLIER_ADD_MODAL,
  payload: {
    open,
    triggerLocation,
    addToBooked,
    defaultCategory,
  },
});

export const createCustomSupplierSuccess =
  (
    supplier: IUISupplier,
    method: BookedMethod,
    booked?: boolean,
    shortlistedLocation?: ShortlistedLocation,
    showSnackbar?: boolean,
  ) =>
  ({ dispatch, getState }: IDeps) => {
    const {
      shortlist: { addToBooked },
    } = getState();
    dispatch(
      saveToShortlist(
        { ...supplier, booked: booked || addToBooked },
        'tile',
        shortlistedLocation,
        showSnackbar,
      ),
    );
    dispatch(clearCustomSupplierForm());
    return {
      type: ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER_SUCCESS,
      payload: { supplier, method },
    };
  };

export const customSupplierFormShowNextError = () => ({
  type: ShortlistActionTypes.CUSTOM_SUPPLIER_FORM_SHOW_NEXT_ERROR,
});

export const customSupplierFormValid = () => ({
  type: ShortlistActionTypes.CUSTOM_SUPPLIER_FORM_VALID,
});

export const createCustomSupplier = (
  method: BookedMethod,
  supplier?: IUISupplier,
  booked?: boolean,
  shortlistedLocation?: ShortlistedLocation,
  showSnackbar: boolean = true,
) => ({
  type: ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER,
  payload: {
    method,
    supplier,
    booked,
    shortlistedLocation,
    showSnackbar,
  },
});

export const setCustomSupplierFormField = (event: ICustomTarget) => {
  const {
    target: { name, value },
  } = event;
  return {
    type: ShortlistActionTypes.SET_CUSTOM_SUPPLIER_FORM_FIELD,
    payload: { name, value },
  };
};

export const clickedSupplierContactLink = (
  supplier: ISupplier | undefined,
  type: string,
  location: string,
): Action => ({
  type: SupplierActions.CLICKED_SUPPLIER_CONTACT_LINK_ANALYTICS,
  payload: { supplier, type, location },
});

export const toggleSupplierDetailsDrawer = (supplierId: string | null = null) => ({
  type: ShortlistActionTypes.TOGGLE_SUPPLIER_DETAILS_DRAWER,
  payload: { supplierId },
});

/**
 * Save notes inside the supplier details drawer
 * @param notes
 */
export const saveShortlistedSupplierNotes =
  ({ notes }: { notes: IWeddingSupplier['notes'] }) =>
  ({ getState, dispatch }: IDeps) => {
    const previousSupplier = getOpenedSupplierDetails(getState()) || ({} as IWeddingSupplier);
    const supplier = { ...previousSupplier, notes } as IWeddingSupplier;
    const isChanged = supplier.notes !== previousSupplier.notes;

    if (isChanged) {
      dispatch({
        type:
          previousSupplier.notes === ''
            ? 'ADDED_SUPPLIER_NOTE_ANALYTICS'
            : 'EDITED_SUPPLIER_NOTE_ANALYTICS',
        payload: { supplier },
      });
    }

    return updateShortlistedSupplier({ supplier });
  };

/**
 * Save quote inside the supplier details drawer
 * @param price
 */
export const saveShortlistedSupplierPrice =
  ({ price }: { price: IWeddingSupplier['price'] }) =>
  ({ getState, dispatch }: IDeps) => {
    const previousSupplier = getOpenedSupplierDetails(getState()) || ({} as IWeddingSupplier);
    const supplier = { ...previousSupplier, price } as IWeddingSupplier;
    const isChanged = supplier.price !== previousSupplier.price;

    if (isChanged) {
      if (supplier.booked) dispatch(updateUserBudget(supplier.type));
      dispatch({
        type: !previousSupplier.price
          ? 'ADDED_SUPPLIER_QUOTE_ANALYTICS'
          : 'EDITED_SUPPLIER_QUOTE_ANALYTICS',
        payload: {
          supplier,
        },
      });
    }

    return updateShortlistedSupplier({ supplier });
  };

/**
 * Save visit date inside the supplier details drawer
 * @param visitDate
 */
export const saveShortlistedSupplierVisitDate =
  ({ visitDate }: { visitDate: IWeddingSupplier['visitDate'] }) =>
  ({ getState, dispatch }: IDeps) => {
    const previousSupplier = getOpenedSupplierDetails(getState()) || ({} as IWeddingSupplier);
    const supplier = { ...previousSupplier, visitDate } as IWeddingSupplier;
    const isChanged = supplier.visitDate !== previousSupplier.visitDate;

    if (isChanged) {
      const isAdded = !previousSupplier.visitDate || isEmpty(previousSupplier.visitDate);
      const isRemoved = !supplier.visitDate || isEmpty(supplier.visitDate);

      if (isAdded) {
        dispatch(addedSupplierVisitDateAnalytics(supplier));
      } else if (isRemoved) {
        dispatch(removedSupplierVisitDateAnalytics(supplier));
      } else {
        dispatch(editedSupplierVisitDateAnalytics(supplier));
      }
    }

    return updateShortlistedSupplier({ supplier });
  };

export const updateShortlistedSupplier = (payload: { supplier: IWeddingSupplier }) => ({
  type: ShortlistActionTypes.UPDATE_SHORTLISTED_SUPPLIER,
  payload,
});

export const toggledTabNavigationOnShortlistAnalytics = (tab: TShortlistTab) => ({
  type: ShortlistActionTypes.TOGGLED_TAB_NAVIGATION_ON_SHORTLIST_ANALYTICS,
  payload: tab,
});

export const unbookAllVenues =
  () =>
  ({ getState }: IDeps) => {
    const { list } = getState().shortlist;
    const isBookedVenue = (item: IWeddingSupplier) => item.booked && isSupplierVenue(item);
    const bookedVenues = filter<IUISupplier & IWeddingSupplier>(
      isBookedVenue,
      list as Dictionary<IUISupplier & IWeddingSupplier>,
    );

    const venueIds = values(bookedVenues).map((venue) => venue.id);

    return {
      type: ShortlistActionTypes.UNBOOK_ALL_VENUES,
      payload: { venueIds },
    };
  };

/**
 * Toggle modal to add a new international supplier.
 * If category (slug) is passed, the form is locked to this category
 * and the category dropdown is hidden. If not, defaults to 'venue' and
 * the category dropdown is shown to allow selection.
 */
export const toggleGoogleSearchModal = ({
  show = false,
  category,
}: {
  show: boolean;
  category?: Slug | '';
}) => ({
  type: ShortlistActionTypes.TOGGLE_GOOGLE_SEARCH_MODAL,
  payload: { show, category },
});

export const toggleCustomSupplierFormModal = (
  show = false,
  finished = false,
  method = 'shortlist',
  defaultCategory: Slug = 'venue',
) => ({
  type: ShortlistActionTypes.TOGGLE_CUSTOM_SUPPLIER_FORM_MODAL,
  payload: { show, finished, method, defaultCategory },
});

export const markIgnored =
  (supplier: IUISupplier) =>
  ({ getState }: IDeps) => {
    const {
      weddings: {
        profile: { id },
      },
    } = getState();

    saveSupplierInWeddingSuppliers({ ...supplier, shortlisted: false, ignored: true }, id);

    return {
      type: ShortlistActionTypes.MARK_SUPPLIER_IGNORED,
      payload: { supplier },
    };
  };

export const removedFromRecommendationsAnalytics = (
  supplier: IUISupplier,
  removedFromLocation?: string,
) => ({
  type: ShortlistActionTypes.REMOVED_SUPPLIER_FROM_RECOMMENDATIONS_ANALYTICS,
  payload: { supplier, removedFromLocation },
});

export const setShortlistedLocation = (shortlistedLocation: ShortlistedLocation) => ({
  type: ShortlistActionTypes.SET_SHORTLISTED_LOCATION,
  payload: { shortlistedLocation },
});

export const triggeredBookedSupplierPopupAnalytics = (bookedPopupLocation?: string) => ({
  type: ShortlistActionTypes.TRIGGERED_BOOKED_SUPPLIER_POP_UP_ANALYTICS,
  payload: bookedPopupLocation,
});

export const triggeredAddSupplierPopupAnalytics = () => ({
  type: ShortlistActionTypes.TRIGGERED_ADD_SUPPLIER_POP_UP_ANALYTICS,
});

export const addedSupplierVisitDateAnalytics = (supplier: IWeddingSupplier) => ({
  type: ShortlistActionTypes.ADDED_SUPPLIER_VISIT_DATE_ANALYTICS,
  payload: supplier,
});

export const editedSupplierVisitDateAnalytics = (supplier: IWeddingSupplier) => ({
  type: ShortlistActionTypes.EDITED_SUPPLIER_VISIT_DATE_ANALYTICS,
  payload: supplier,
});

export const removedSupplierVisitDateAnalytics = (supplier: IWeddingSupplier) => ({
  type: ShortlistActionTypes.REMOVED_SUPPLIER_VISIT_DATE_ANALYTICS,
  payload: supplier,
});

export const toggleSupplierCollaboration = (payload: {
  supplier: IUISupplier;
  isCollaborating: boolean;
}) => ({
  type: ShortlistActionTypes.TOGGLE_SUPPLIER_COLLABORATION,
  payload,
});

export const fetchSupplierCollaboration = (supplierId: IWeddingSupplier['id']) => ({
  type: ShortlistActionTypes.FETCH_SUPPLIER_COLLABORATION,
  payload: { supplierId },
});

export const fetchSupplierCollaborationSuccess = (payload: ICollaborationResponse) => ({
  type: ShortlistActionTypes.FETCH_SUPPLIER_COLLABORATION_SUCCESS,
  payload,
});

export const bookedSupplierAnalytics = ({
  supplier,
  method,
}: {
  supplier: IUISupplier;
  method: BookedMethod;
}) => ({
  type: ShortlistActionTypes.BOOKED_SUPPLIER_ANALYTICS,
  payload: { item: supplier, method },
});

export const unbookSupplierAnalytics = ({
  supplier,
  method,
}: {
  supplier: ISupplier;
  method: BookedMethod;
}) => ({
  type: ShortlistActionTypes.UNBOOK_SUPPLIER_ANALYTICS,
  payload: { item: supplier, method },
});

export const showShortlistUserLimitModal = (show: boolean) => ({
  type: ShortlistActionTypes.SHOW_SHORTLIST_USER_LIMIT_MODAL,
  payload: { show },
});

/**
 * Toggle modal to confirm marking supplier as booked
 */
export const toggleConfirmBookingModal = (
  supplierToBook: IUISupplier | null,
  triggerLocation = '',
) => ({
  type: ShortlistActionTypes.TOGGLE_CONFIRM_BOOKING_MODAL,
  payload: { supplierToBook, triggerLocation },
});

/**
 * Confirm marking a supplier as booked
 */
export const confirmBookingSupplier =
  (supplier?: IUISupplier, triggerLocationParam?: string) =>
  ({ dispatch, getState }: IDeps) => {
    const state = getState();
    const { supplierToBook, triggerLocation = '' } = state.shortlist.confirmBookingModal || {};
    if (supplierToBook || supplier) {
      const bookedSupplier = supplier
        ? { ...supplier, booked: true }
        : { ...supplierToBook, booked: true };

      // Added setTimeout to fix breaking modals when a new one is shown
      setTimeout(() => {
        dispatch(
          saveToShortlist(
            bookedSupplier as IUISupplier,
            'confirmSupplierPopup',
            (triggerLocationParam ?? triggerLocation) as ShortlistedLocation,
            false,
          ),
        );
        dispatch(
          bookedSupplierAnalytics({
            supplier: bookedSupplier as IUISupplier,
            method: (triggerLocationParam ?? triggerLocation) as BookedMethod,
          }),
        );
      }, 0);
    }

    return closeBookingConfirmationModal();
  };

export const setFromBookedSupplier = (payload: boolean) => ({
  type: ShortlistActionTypes.SET_FROM_BOOKED_SUPPLIER,
  payload,
});

// export const fetchSupplierConnections
