import { equals } from 'ramda';
import { ValidationError } from '@bridebook/toolbox/src';
import { CohortNames } from '@bridebook/toolbox/src/ab-testing/ab-testing';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { countriesWithRequiredPhoneNumber } from '@bridebook/toolbox/src/i18n/features';
import { countriesWithForcePhoneNumberABTest } from 'lib/ab-tests/tests/global/LIVE19141_ForceIEAndFRCouplesToEnterPhoneNumberBeforeSendingEnquiries';
import { datePickerDefault } from 'lib/datepicker/utils/datepicker-default';
import { EnquiryFormFieldsType } from 'lib/enquiries/types';
import { getI18n } from 'lib/i18n/getI18n';
import validate from 'lib/validate';

const validateEnquiryForm = async ({
  fields,
  isEdit = false,
  needLocation = true,
  countryCode,
  forcePhoneNumberVariant,
}: {
  fields: EnquiryFormFieldsType;
  isEdit: boolean;
  needLocation: boolean;
  countryCode: CountryCodes;
  forcePhoneNumberVariant?: CohortNames;
}) => {
  const errorMissingFields = 'Please fill out all missing fields';
  const errorMissingFieldsI18n = getI18n().t('common:error.fillOutMissingFields');
  const commonValidationError = {
    message: errorMissingFields,
    messageI18n: errorMissingFieldsI18n,
  };

  let validation = validate<EnquiryFormFieldsType>(fields)
    .prop('email')
    .required(() => commonValidationError)
    .prop('email')
    .email();

  const isPhoneNumberRequired =
    forcePhoneNumberVariant === 'control'
      ? countriesWithRequiredPhoneNumber.includes(countryCode)
      : countriesWithRequiredPhoneNumber
          .concat(countriesWithForcePhoneNumberABTest)
          .includes(countryCode);

  if (isPhoneNumberRequired) {
    validation = validation.prop('phone').phone().required();
  }

  await validation.promise;

  if (!fields.partners[0]) {
    throw new ValidationError(
      errorMissingFields,
      'partnerName1',
      undefined,
      errorMissingFieldsI18n,
    );
  }

  if (!fields.partners[1]) {
    throw new ValidationError(
      errorMissingFields,
      'partnerName2',
      undefined,
      errorMissingFieldsI18n,
    );
  }

  if (!fields.guestsInitialTarget || fields.guestsInitialTarget < 1) {
    throw new ValidationError<keyof EnquiryFormFieldsType>(
      errorMissingFields,
      'guestsInitialTarget',
      undefined,
      errorMissingFieldsI18n,
    );
  }

  if (!fields.weddingDateDatePicker || equals(datePickerDefault, fields.weddingDateDatePicker)) {
    throw new ValidationError<keyof EnquiryFormFieldsType>(
      errorMissingFields,
      'weddingDate',
      undefined,
      errorMissingFieldsI18n,
    );
  }

  if (!isEdit) {
    await validate(fields)
      .prop('message')
      .required(() => ({
        message: 'Please enter your message',
        messageI18n: getI18n().t('enquiries:error.enterMessage'),
      })).promise;
  }

  if (needLocation) {
    await validate(fields)
      .prop('location')
      .required(() => commonValidationError).promise;

    if (!fields.location?.name) {
      throw new ValidationError<keyof EnquiryFormFieldsType>(
        errorMissingFields,
        'location',
        undefined,
        errorMissingFieldsI18n,
      );
    }
  }

  return true;
};

export const isEnquiryFormValid = async ({
  fields,
  isEdit = false,
  needLocation = true,
  countryCode,
  onError,
}: {
  fields: EnquiryFormFieldsType;
  isEdit?: boolean;
  needLocation?: boolean;
  countryCode: CountryCodes;
  onError?: (error: unknown) => void;
}) => {
  try {
    await validateEnquiryForm({
      fields,
      isEdit,
      needLocation,
      countryCode,
    });

    return true;
  } catch (error) {
    onError?.(error);
    return false;
  }
};

export default validateEnquiryForm;
