export const env = {
  API_ZENDESK_WIDGET: process.env.NEXT_PUBLIC_API_ZENDESK_WIDGET,
  COUPLESIDE_URL: process.env.NEXT_PUBLIC_COUPLESIDE_URL,
  SEGMENTID: process.env.NEXT_PUBLIC_SEGMENTID,
  STREAM_KEY: process.env.NEXT_PUBLIC_STREAM_KEY,
  STREAM_SECRET: process.env.STREAM_SECRET,
  CMS_URL: process.env.NEXT_PUBLIC_CMS_URL,
  CMS_LANDING_URL: process.env.NEXT_PUBLIC_CMS_LANDING_URL,
  CMS_URL_I18N: process.env.NEXT_PUBLIC_CMS_URL_I18N,
  FIREBASE: {
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  },
  ENQUIRY_WIDGET_URL: process.env.NEXT_PUBLIC_ENQUIRY_WIDGET_URL,
  STRIPE_API_KEY: process.env.NEXT_PUBLIC_STRIPE_API_KEY,
  BEST_OF_BRITAIN: process.env.NEXT_PUBLIC_BEST_OF_BRITAIN === 'enabled',
  LIVE: process.env.NEXT_PUBLIC_DATABASE === 'production',
  IS_PRODUCTION_BUILD: process.env.NODE_ENV === 'production',
  ENABLE_MIXPANEL_FETCH: process.env.ENABLE_MIXPANEL_FETCH,
  CROWDIN_IN_CONTEXT: process.env.NEXT_PUBLIC_CROWDIN_IN_CONTEXT === 'true',
  CHARGEBEE_SITE: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
  CHARGEBEE_API_KEY: process.env.CHARGEBEE_API_KEY,
  CHARGEBEE_WEBHOOK_USERNAME: process.env.CHARGEBEE_WEBHOOK_USERNAME,
  CHARGEBEE_WEBHOOK_PASSWORD: process.env.CHARGEBEE_WEBHOOK_PASSWORD,
  SLACK_WEBHOOK_ERRORS: process.env.SLACK_WEBHOOK_ERRORS,
  SLACK_SALES_BELL: process.env.SLACK_SALES_BELL,
  INSTAGRAM_APP_ID: process.env.NEXT_PUBLIC_INSTAGRAM_APP_ID,
  INSTAGRAM_APP_SECRET: process.env.INSTAGRAM_APP_SECRET,
  PLATFORM_VERSION: process.env.NEXT_PUBLIC_PLATFORM_VERSION,
  CUSTOMERIO_APP_API_KEY: process.env.CUSTOMERIO_APP_API_KEY,
};
