import React, { MouseEventHandler, ReactNode, useCallback, useEffect, useState } from 'react';
import Box from '../../../components/fela/Box';
import { FelaCSS } from '../../../components/fela/flowtypes';
import { Radio } from './radio';

interface IProps {
  onSelect: MouseEventHandler<HTMLInputElement>;
  options: Array<{ label: ReactNode; value: string }>;
  name: string;
  selectedOption?: string;
  style?: FelaCSS;
  radioWrapperStyle?: (isChecked?: boolean) => FelaCSS;
}

export const RadioGroup = ({
  onSelect,
  options,
  name,
  selectedOption,
  style,
  radioWrapperStyle,
}: IProps) => {
  const [currentSelected, setCurrentSelected] = useState<string | undefined>(selectedOption);

  useEffect(() => {
    setCurrentSelected(selectedOption);
  }, [selectedOption]);

  const handleChange: MouseEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setCurrentSelected(event.currentTarget.value);
      onSelect?.(event);
    },
    [onSelect],
  );

  return (
    <Box style={style}>
      {options.map((option) => {
        const isChecked = currentSelected === option.value;
        const radioStyle = radioWrapperStyle ? radioWrapperStyle(isChecked) : undefined;
        return (
          <Box key={option.value} style={radioStyle}>
            <Radio option={option} name={name} onClick={handleChange} checked={isChecked} />
          </Box>
        );
      })}
    </Box>
  );
};
