import React from 'react';
import { connect } from 'react-redux';
import { PersistentMessage } from '@bridebook/ui';
import { hidePersistentMessage } from 'lib/bbcommon/actions';
import { PersistentMessageType } from 'lib/bbcommon/types';
import { IApplicationState } from 'lib/types';

interface IProps {
  hidePersistentMessage(): unknown;

  persistentMessageState: PersistentMessageType;
}

const PersistentMessageWrap = ({ hidePersistentMessage, persistentMessageState }: IProps) => (
  <PersistentMessage {...{ close: hidePersistentMessage, ...persistentMessageState }} />
);

export default connect(
  (state: IApplicationState) => ({
    persistentMessageState: state.bbcommon.persistentMessageState,
  }),
  { hidePersistentMessage },
)(PersistentMessageWrap);
