import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  toggleViewButton: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    paddingLeft: 3,
    pointerEvents: 'auto',
  },

  toggleViewButton: {
    height: 35,
    fontDefault: 14,
    color: colors.white,
    paddingHorizontal: 4,
    cursor: 'pointer',
    gap: 8,
    lineHeight: '150%',
  },
});

export default styles;
