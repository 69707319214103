import React, { SyntheticEvent, useCallback } from 'react';
import MessageContainer from '../../../components/bbcommon/message-container';
import { PersistentMessageStateType } from '../../../components/bbcommon/persistent-message/types';
import Box from '../../../components/fela/Box';
import Text from '../../../components/fela/Text';
import IconCrossBold from '../../../components/svg/icon-cross-bold';
import { IStylingProps } from '../../../themes/types';
import { TColor } from '../../../types';
import Button from '../buttons/button';
import componentStyles from './persistent-message-container.style';

interface IProps extends PersistentMessageStateType, IStylingProps {
  close?(): unknown;
}

const PersistentMessageContainer = ({
  theme,
  header,
  text,
  buttonSecondaryText,
  buttonPrimaryText,
  buttonSecondaryAction,
  buttonPrimaryAction,
  closeAction,
  close,
  ...restProps
}: IProps) => {
  const onClose = useCallback(() => {
    if (closeAction) closeAction();
    if (close) close();
  }, [close, closeAction]);

  const getIconColor = useCallback((): TColor => {
    if (theme === 'mega-alert') return 'white';
    return 'silver';
  }, [theme]);

  const styles = componentStyles({ theme });
  const defaultCallback = useCallback((e: SyntheticEvent<any>) => e, []);

  return (
    <MessageContainer theme={theme} {...restProps}>
      <Box style={styles.container}>
        <Box style={styles.headingWrap}>
          {header && <Text style={styles.heading}>{header}</Text>}
          {text && <Text style={styles.text}>{text}</Text>}
        </Box>
        {(buttonSecondaryText || buttonPrimaryText) && (
          <Box style={styles.buttonWrapper}>
            {buttonSecondaryText && (
              <Box style={{ marginRight: '8px' }}>
                <Button
                  size="tiny"
                  theme="secondary"
                  text={buttonSecondaryText}
                  onClick={buttonSecondaryAction || defaultCallback}
                />
              </Box>
            )}
            {buttonPrimaryText && (
              <Box>
                <Button
                  size="tiny"
                  theme={theme === 'mega-alert' ? undefined : 'primary'}
                  text={buttonPrimaryText}
                  onClick={buttonPrimaryAction || defaultCallback}
                />
              </Box>
            )}
          </Box>
        )}

        {close && (
          <Box style={styles.closeWrapper}>
            <Button
              name="close-message"
              theme="ghost"
              icon={<IconCrossBold color={getIconColor()} />}
              onClick={onClose}
            />
          </Box>
        )}
      </Box>
    </MessageContainer>
  );
};

export default PersistentMessageContainer;
