import { EnquiryIntentType } from './types';

const EnquiryIntent: EnquiryIntentType = {
  info: false,
  brochure: false,
  pricing: false,
  dates: false,
  availability: false,
  quote: false,
  showround: false,
  videotour: false,
  other: false,
};

export default EnquiryIntent;
