import React, { MouseEvent, memo, useCallback } from 'react';
import { Box, RadioGroup } from '@bridebook/ui';
import useRadiusIncreaseFilter from 'lib/search/hooks/use-radius-filter';
import componentStyles from './search-increase-radius-filter.style';

const SearchIncreaseRadiusFilter = () => {
  const { optionItems, checkedOptions, onFilterApply, defaultRadioValue, label } =
    useRadiusIncreaseFilter('filtersModal');

  const checkedOption = checkedOptions[0];
  const radioOptions = optionItems.map((option) => ({
    label: option.primaryText,
    value: option.key,
  }));

  const onRadioSelect = useCallback(
    (event: MouseEvent<HTMLInputElement>) => {
      const selectedOption = optionItems.find(
        (optionItem) => optionItem.key.toString() === event.currentTarget.value,
      );

      selectedOption && onFilterApply(selectedOption.value, true);
    },
    [optionItems, onFilterApply],
  );

  const styles = componentStyles();

  return (
    <>
      <Box as="h2" style={styles.heading}>
        {label} {checkedOption && <Box as="span">&nbsp;(1)</Box>}
      </Box>
      <Box style={styles.wrapper}>
        <RadioGroup
          name={`${label}-filtersModal`}
          options={radioOptions}
          onSelect={onRadioSelect}
          selectedOption={checkedOption?.key || defaultRadioValue}
          radioWrapperStyle={(isChecked) => styles.radioWrapper(isChecked)}
          style={styles.radioGroup}
        />
      </Box>
    </>
  );
};

export default memo(SearchIncreaseRadiusFilter);
