import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Box, Button } from '@bridebook/ui';
import { useApplyFilters, useToggleFilters } from 'components/search/hooks';
import { areFiltersShown, getFiltersCount } from 'lib/search/selectors';
import { ConnectedTypes, IApplicationState, IUrl } from 'lib/types';
import FiltersContainer from '../filters-container/filters-container';
import componentStyles from './filter-mobile-form.style';
import FilterTopBar from './filter-top-bar';

const storeEnhancer = connect((state: IApplicationState) => ({
  filtersCount: getFiltersCount(state),
  isMobile: state.app.device.isMobile,
  filtersShown: areFiltersShown(state),
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {
  location: IUrl;

  close(): void;
}

const FilterMobileForm = ({ location, filtersShown, close }: IProps) => {
  const { t } = useTranslation('search');

  const applyFilters = useApplyFilters(filtersShown, location);

  const toggleFilters = useToggleFilters({ location, filtersShown });

  const applyFiltersCb = useCallback(() => {
    applyFilters();
    toggleFilters();
  }, [applyFilters, toggleFilters]);

  const styles = componentStyles();

  return (
    <Box style={styles.wrapper}>
      <FilterTopBar close={close} />
      <FiltersContainer />
      <Box style={styles.buttonWrap}>
        <Button text={t('applyFilters')} theme="primary" width="block" onClick={applyFiltersCb} />
      </Box>
    </Box>
  );
};

export default storeEnhancer(FilterMobileForm);
