import { FelaCSS, FelaCSSWithCustomSelectors, colors } from '@bridebook/ui';

interface IStyles {
  label: FelaCSS;
  input: FelaCSS;
  checkmark: FelaCSSWithCustomSelectors;
}

const styles = (checked: boolean): IStyles => ({
  label: {
    fontDefault: 'inherit',
    flexDirection: 'row',
    cursor: 'pointer',
    width: '100%',
    position: 'relative',
    alignItems: 'center',
  },
  checkmark: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '24px',
    height: '24px',
    border: `2px solid ${colors.space40}`,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.2s ease-in-out',
    boxSizing: 'border-box',

    ...(checked && {
      border: `2px solid ${colors.indigoCrush}`,
    }),

    ':after': {
      content: '""',
      display: 'block',
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      backgroundColor: colors.indigoCrush,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out',

      ...(checked && {
        opacity: 1,
        border: `1px solid ${colors.blueCrush}`,
      }),
    },
  },

  input: {
    height: 24,
    width: 24,
    marginRight: 8,
    marginTop: 0,
  },
});

export default styles;
