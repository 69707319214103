import React from 'react';
import { SearchByNameModalProps } from 'components/search/searchbar2/search-by-name-modal/search-by-name-modal';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const SearchByLocationModal = lazyComponent<SearchByNameModalProps>(
  () => import('components/search/searchbar2/search-by-location/search-by-location-modal'),
);

interface SearchByLocationModalLazyProps {}

const SearchByLocationModalLazy: React.FC<SearchByLocationModalLazyProps> = () => {
  const show = useSelector((state) => state.ui.searchByLocationModal.show);
  if (!show) return null;

  return <SearchByLocationModal show={show} />;
};

export default React.memo(SearchByLocationModalLazy);
