import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import { Box, Pill } from '@bridebook/ui';
import { IconLocation, IconSupplierList } from '@bridebook/ui/src/icons/dynamic';
import componentStyles from 'components/search/toggle-view-button/toggle-view-button.style';
import { toggleMapView } from 'lib/search/actions';
import { getIsMapView } from 'lib/search/selectors';
import { clickedAnalytics } from 'lib/ui/analytics';
import { useDispatch, useSelector } from 'lib/utils';

export const ToggleViewButton = () => {
  const { t } = useTranslation('search');
  const isMapView = useSelector(getIsMapView);
  const dispatch = useDispatch();

  const onToggleMapView = useCallback(() => {
    const showMap = !isMapView;
    dispatch(
      clickedAnalytics({
        category: 'search',
        clickedSection: 'mapButtonTop',
        clickedLocation: 'searchResults',
        cta: isMapView ? 'View search results in list view' : 'View search results in map view',
      }),
    );
    dispatch(toggleMapView(showMap));
  }, [dispatch, isMapView]);

  const styles = componentStyles();

  return (
    // Extra wrapper to make a bit more surrounding area clickable
    <Box onClick={onToggleMapView} style={styles.wrapper}>
      <Pill sidePaddings={12} height={40} color="space" style={styles.toggleViewButton}>
        {isMapView ? (
          <>
            {t('toggleView.listView')}
            <IconSupplierList color="white" width={14} mt="1px" />
          </>
        ) : (
          <>
            {t('toggleView.mapView')} <IconLocation color="white" width={14} />
          </>
        )}
      </Pill>
    </Box>
  );
};
