import { useDispatch } from 'react-redux';
import { updateFiltersCount, updateSearchUrl } from 'lib/search/actions';
import { IUrl } from 'lib/types';

type IUseApplyFilters = (filtersShown: boolean, location: IUrl) => () => void;

/**
 * useApplyFilters hook:
 *  - applies selected filters by updating url
 *  - updates filter count value in the store
 *
 * It requires filters to be visible to process.
 */
export const useApplyFilters: IUseApplyFilters = (filtersShown, location) => {
  const dispatch = useDispatch();
  return () => {
    if (filtersShown) {
      dispatch(updateSearchUrl({ location }));
      dispatch(updateFiltersCount());
    }
  };
};
