import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  heading: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  heading: {
    fontDefaultSemiBold: 16,
    marginTop: 0,
    marginBottom: 6,
  },
});

export default styles;
