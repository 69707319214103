import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { TRefSupplier } from 'app-shared/lib/supplier/types';
import { ActionWithPayload } from 'lib/types';
import {
  downloadedSupplierBrochureAnalytics,
  fetchFeedbackDone,
  fetchPremiumDone,
  fetchSupplierDataDone,
  fetchSupplierError,
  fetchSupplierPhotosDone,
  fetchSupplierStart,
  saveReviewError,
  setReviewFormField,
  setReviewFormRating,
  setSupplierDeleted,
  supplierCardInView,
  supplierGalleryToggle,
  supplierVideoModalToggle,
  toggleReviewForm,
  toggleSupplierAccordion,
  toggleSupplierReviewsPage,
  toggleWhyBridebookModalAnalytics,
  uploadReviewPhotoSuccess,
} from './actions';

export enum SupplierActions {
  CLICKED_GO_TO_MESSAGE_ANALYTICS = '[Analytics] Clicked to go to message',
  CLICKED_NEXT_SUPPLIER_PROFILE_ANALYTICS = '[Analytics] [Supplier] Clicked next supplier profile',
  CLICKED_ON_FACETTING_LINK_ANALYTICS = '[Analytics] [Supplier] Clicked on facetting link',
  CLICKED_SUPPLIER_CONTACT_LINK_ANALYTICS = '[Analytics] [Supplier] Clicked supplier contact link',
  CLICKED_SUPPLIER_SOCIAL_MEDIA_ANALYTICS = '[Analytics] [Supplier] Clicked supplier social media',
  CLICKED_TO_TRIGGER_ENQUIRY_FORM_ANALYTICS = '[Analytics] [Supplier] Clicked to trigger enquiry form',
  CLICKED_UP_NEXT_VENUE_ANALYTICS = '[Analytics] [Supplier] Clicked up next venue',
  CLICKED_SEE_ALL_REVIEWS = '[Analytics] [Supplier] Clicked see all reviews',
  CLICKED_SEE_REVIEW = '[Analytics] [Supplier] Clicked see review',
  CLICKED_SHOW_MORE_REVIEW = '[Analytics] [Supplier] Clicked show more review',
  CLOSED_REVIEW_MODAL_ANALYTICS = '[Analytics] [Supplier] Closed review modal',
  DOWNLOADED_SUPPLIER_BROCHURE_ANALYTICS = '[Supplier] [Analytics] Downloaded supplier brochure',
  DOWNLOAD_SUPPLIER_BROCHURE = '[Supplier] Download supplier brochure',
  FAILED_TO_SUBMIT_REVIEW_ANALYTICS = '[Analytics] [Supplier] Failed to submit review',
  FETCH_FEEDBACK = '[Supplier] Fetch feedback',
  FETCH_FEEDBACK_DONE = '[Supplier] Fetch feedback done',
  FETCH_FEEDBACK_ERROR = '[Supplier] Fetch feedback error',
  FETCH_FEEDBACK_STOP = '[Supplier] Fetch feedback stop',
  FETCH_PHOTOS = '[Supplier] Fetch photos',
  FETCH_PHOTOS_DONE = '[Supplier] Fetch photos done',
  FETCH_PREMIUM_DONE = '[Supplier] Fetch premium done',
  FETCH_SUPPLIER_PHOTOS_ERROR = '[Supplier] Fetch photos error',
  FETCH_SUPPLIER_START = '[Supplier] Fetch supplier start',
  FETCH_SUPPLIER_SUCCESS = '[Supplier] Fetch supplier success',
  FETCH_SUPPLIER_ERROR = '[Supplier] Fetch supplier error',
  FETCH_SUPPLIER_DATA_DONE = '[Supplier] Fetch supplier data done',
  SET_SUPPLIER_DELETED = '[Supplier] Set supplier deleted',
  GOTO_NEXT_SUPPLIER = '[Supplier] Go to next supplier',
  HIDE_REVIEW_FORM_ERROR = '[Supplier] Hide review form error',
  HIDE_REVIEW_SUCCESS = '[Supplier] Hide review success',
  REDEEM_SUPPLIER_SPECIAL_OFFER = '[Supplier] Redeem special offer',
  RESET_REVIEW_FORM = '[Supplier] Reset review form',
  RESET_SUPPLIER_ACCORDION = '[Supplier] Reset accordion',
  REVEALED_MORE_CONTENT_ON_SUPPLIER_PROFILE_ANALYTICS = '[Analytics] [Supplier] Revealed more content on supplier profile',
  REVIEW_FORM_RECAPTCHA_VERIFIED = '[Supplier] Review from recaptcha veriefied',
  SAVE_REVIEW = '[Supplier] Save review',
  SAVE_REVIEW_ERROR = '[Supplier] Save review error',
  SAVE_REVIEW_SUCCESS = '[Supplier] Save review success',
  SET_REVIEW_FORM_FIELD = '[Supplier] Set review form field',
  SET_REVIEW_FORM_RATING = '[Supplier] Set review form rating',
  SHOW_REVIEW_SUCCESS = '[Supplier] Show review success',
  SUBMITTED_REVIEW_ANALYTICS = '[Analytics] [Supplier] Submitted review',
  SUPPLIER_CARD_IN_VIEW = '[Supplier] Supplier card in view',
  SUPPLIER_GALLERY_LOAD_PHOTOS = '[Supplier] Gallery load photos',
  SUPPLIER_GALLERY_TOGGLE = '[Supplier] Gallery toggle',
  SUPPLIER_REVIEWS_TOGGLE = '[Supplier] Reviews toggle',
  SUPPLIER_VIDEO_MODAL_TOGGLE = '[Supplier] Video modal toggle',
  TOGGLED_SUPPLIER_MEDIA_GALLERY_ANALYTICS = '[Analytics] [Supplier] Toggled supplier media gallery',
  SCROLLED_SUPPLIER_MEDIA_GALLERY_ANALYTICS = '[Analytics] [Supplier] Scrolled supplier media gallery',
  VIEWED_MOOD_BOARD_ANALYTICS = '[Analytics] [Supplier] Viewed mood board',
  TOGGLE_REVIEW_FORM = '[Supplier] Toggle review form',
  TOGGLE_SUPPLIER_ACCORDION = '[Supplier] Toggle accordion',
  TOGGLE_WHY_BRIDEBOOK_MODAL = '[Supplier] Toggle why bridebook modal',
  TOGGLE_WHY_BRIDEBOOK_MODAL_ANALYTICS = '[Analytics] [Supplier] Toggle why bridebook modal analytics',
  TRIGGERED_REVIEW_MODAL_ANALYTICS = '[Analytics] [Supplier] Triggered review modal',
  TRIGGERED_SUPPLIER_SOCIAL_SHARE_ANALYTICS = '[Analytics] [Supplier] Triggered social share',
  SUPPLIER_AND_COUPLE_COLLABORATING_ANALYTICS = '[Analytics] [Supplier] Supplier and couple collaborating',
  UPLOAD_REVIEW_PHOTO = '[Supplier] Upload review photo',
  UPLOAD_REVIEW_PHOTO_ERROR = '[Supplier] Upload review photo error',
  UPLOAD_REVIEW_PHOTO_SUCCESS = '[Supplier] Upload review photo success',
  USED_QUICK_NAVIGATION_ON_SUPPLIER_PROFILE_ANALYTICS = '[Analytics] [Supplier] Used quick navigation on supplier profile',
  VIEWED_END_SCREEN_ON_SUPPLIER_CAROUSEL_ANALYTICS = '[Analytics] [Supplier] Viewed end screen on supplier carousel',
  VIEWED_SUPPLIER_PROFILE_ANALYTICS = '[Analytics] [Supplier] Viewed supplier profile',
  VIEWED_SUPPLIER_TILE_ANALYTICS = '[Supplier] [Analytics] Viewed supplier tile analytics',
  WATCHED_SUPPLIER_VIDEO_ANALYTICS = '[Analytics] [Supplier] Watched supplier video',
  SHARED_SUPPLIER_PROFILE_LINK_ANALYTICS = 'Shared supplier profile link',
  CLICKED_SUPPLIER_CATEGORY_TAGS_ANALYTICS = '[Analytics] [Supplier] Clicked supplier category tags',
  MAP_IN_VIEW = '[Supplier] [Analytics] Location map in view',
  MAP_MOUSE_UP = '[Supplier] [Analytics] Location map mouse up event',
  INSTAGRAM_GALLERY_VIEWED_ANALYTICS = '[Supplier] [Analytics] Instagram gallery viewed',
  INSTAGRAM_GALLERY_SCROLLED_ANALYTICS = '[Supplier] [Analytics] Instagram gallery scrolled',
}

export type ISupplierBrochureAnalyticsAction = ReturnType<
  typeof downloadedSupplierBrochureAnalytics
>;

export type ISupplierCardInViewAction = ReturnType<typeof supplierCardInView>;

export type IFetchSupplierStartAction = ReturnType<typeof fetchSupplierStart>;

export type IFetchSupplierErrorAction = ReturnType<ReturnType<typeof fetchSupplierError>>;

export type ISaveReviewErrorAction = ReturnType<ReturnType<typeof saveReviewError>>;

export type IToggleReviewFormAction = ReturnType<ReturnType<typeof toggleReviewForm>>;

export type ISetReviewFormFieldAction = ReturnType<typeof setReviewFormField>;

export type ISetReviewFormRatingAction = ReturnType<ReturnType<typeof setReviewFormRating>>;

export type IUploadReviewPhotoSuccessAction = ReturnType<typeof uploadReviewPhotoSuccess>;

export type ISupplierGalleryToggleAction = ReturnType<ReturnType<typeof supplierGalleryToggle>>;

export type ISupplierReviewsToggleAction = ReturnType<ReturnType<typeof toggleSupplierReviewsPage>>;

export type IToggleSupplierAccordionAction = ReturnType<ReturnType<typeof toggleSupplierAccordion>>;

export type ISupplierVideoModalToggleAction = ReturnType<
  ReturnType<typeof supplierVideoModalToggle>
>;

export type IToggleWhyBridebookModalAnalyticsAction = ReturnType<
  typeof toggleWhyBridebookModalAnalytics
>;

export type IViewedSupplierAnalyticsAction = ActionWithPayload<{
  supplier: ISupplier;
  refSupplier?: TRefSupplier;
  otherEnquiriesTotal: number;
  otherViewsTotal: number;
}>;

export type FetchSupplierSuccessAction = ActionWithPayload<{ supplier: ISupplier }>;

export type FetchFeedbackSuccessAction = ReturnType<typeof fetchFeedbackDone>;

export type FetchPremiumDoneAction = ReturnType<typeof fetchPremiumDone>;

export type FetchPhotosDoneAction = ReturnType<typeof fetchSupplierPhotosDone>;

export type FetchSupplierDataDoneAction = ReturnType<typeof fetchSupplierDataDone>;

export type ISetSupplierDeletedAction = ReturnType<typeof setSupplierDeleted>;
