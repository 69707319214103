import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  heading: FelaCSS;
  wrapper: FelaCSS;
  filters: FelaCSS;
  checkboxWrap: FelaCSS;
}

const styles = (): IStyles => ({
  heading: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  wrapper: {
    flexShrink: 0,
    paddingBottom: '8px',
  },

  filters: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },

  checkboxWrap: {
    marginBottom: '16px',
    marginRight: '16px',

    maxWidthSmMax: {
      marginRight: '0',
      width: '50%',
      // @ts-ignore FIXME
      ':nth-of-type(odd)': {
        paddingRight: '8px',
      },

      ':nth-of-type(even)': {
        paddingLeft: '8px',
      },
    },
  },
});

export default styles;
