import React, { memo, useState } from 'react';
import ReactHeight from 'react-height';
import Box from '../../../components/fela/Box';
import { IStylingProps } from '../../../themes/types';
import PersistentMessageContainer from './persistent-message-container';
import componentStyles from './persistent-message.style';
import { PersistentMessageStateType } from './types';

interface Props extends PersistentMessageStateType, IStylingProps {
  close?: () => void;
  show?: boolean;
}

const PersistentMessageComp = (props: Props) => {
  const [height, setHeight] = useState(0);

  const {
    show,
    theme,
    header,
    text,
    buttonSecondaryText,
    buttonPrimaryText,
    buttonSecondaryAction,
    buttonPrimaryAction,
    closeAction,
    close,
    ...restProps
  } = props;

  const containerProps = { ...props };
  delete containerProps.show;

  const styles = componentStyles();

  return (
    <Box data-name="persistent-message" style={styles.wrapper({ show, height })} {...restProps}>
      <ReactHeight onHeightReady={setHeight}>
        <PersistentMessageContainer {...containerProps} />
      </ReactHeight>
    </Box>
  );
};

export const PersistentMessage = memo(PersistentMessageComp);
